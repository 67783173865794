<template>
    <div class="has-text-centered">
        <div class="is-flex is-justify-content-center">
            <div class="mr-2">
                <b-dropdown v-model="InactiveWarningsPerPage" aria-role="list">
                    <b-button slot="trigger" slot-scope="{ active }" size="is-medium">
                        <template>
                            <span>Alertas por página ({{ InactiveWarningsPerPage }})</span>
                            <b-icon :icon="active ? 'arrow-up' : 'arrow-down'" size="small"></b-icon>
                        </template>
                    </b-button>
                    <b-dropdown-item :value="6" aria-role="listitem" class="has-text-left">
                        6
                    </b-dropdown-item>
                    <b-dropdown-item :value="10" aria-role="listitem" class="has-text-left">
                        10
                    </b-dropdown-item>
                    <b-dropdown-item :value="16" aria-role="listitem" class="has-text-left">
                        16
                    </b-dropdown-item>
                    <b-dropdown-item :value="20" aria-role="listitem" class="has-text-left">
                        20
                    </b-dropdown-item>
                    <b-dropdown-item :value="26" aria-role="listitem" class="has-text-left">
                        26
                    </b-dropdown-item>
                    <b-dropdown-item :value="30" aria-role="listitem" class="has-text-left">
                        30
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <b-field label-position="inside" grouped group-multiline position="is-centered">
                <template slot="label">
                    <span class="has-text-primary is-family-sans-serif">{{ $t('warnings.calendar.label') }}</span>
                </template>
                <b-datepicker v-model="SelectedDate" :month-names="GetCalendarLocale.MonthNames" :day-names="GetCalendarLocale.DayNames" :selectable-dates="InactiveWarningsDatesAvailable" icon="calendar-alt" trap-focus>
                </b-datepicker>
                <p class="control">
                    <b-button class="button is-primary" expanded><span class="is-family-sans-serif" @click="GetSelectedDateWarnings">{{ $t('warnings.calendar.button') }}</span></b-button>
                </p>
                <p class="control">
                    <b-button class="button is-primary" @click="GetRecentInactiveWarnings" expanded>
                        <b-icon icon="sync" size="is-small"></b-icon>
                    </b-button>
                </p>
            </b-field>
        </div>
        <br>
        <b-loading :is-full-page="false" :can-cancel="false" v-model="AreInactiveWarningsLoading"></b-loading>
        <div class="container" v-if="InactiveWarnings.length > 0">
            <b-pagination :simple="true" :total="TotalInactiveWarnings" :current.sync="CurrentPage" :per-page="InactiveWarningsPerPage" order="is-centered">
            </b-pagination>
        </div>
        <div class="columns is-multiline  mt-4">
            <MordorInactiveWarnigsCard  v-for="alert in GetCurrentPageWarnings" :key="alert.datetime" :WarningObject='alert' class="column is-6" v-on:WarningReaproved="GetInactiveWarningsDatesAvailable()"/>   
        </div>
    </div>
</template>

<script>
import MordorInactiveWarnigsCard from '@/components/mordor_camControl/MordorInactiveWarnigsCard.vue'

export default {
    name: 'MordorInactiveWarnings',
    components: {
        MordorInactiveWarnigsCard,
    },
    data() {
        return {
            CurrentPage: 1, //Bound to current page of pagination component.
            InactiveWarningsPerPage:6,
            TotalInactiveWarnings: 0, //Total number of warnings available on selected date. This info comes from database.
            InactiveWarningsDatesAvailable: [], 
            SelectedDate: null,
            InactiveWarnings:[],
            AreInactiveWarningsLoading: false,
        }
    },
    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
        GetSelectedCam() {
            return this.$store.getters['database/GetCurrentCamera']
        },
        GetCurrentPageWarnings() {
            let pageNumber = this.CurrentPage - 1 //Decrease 1 on CurrentPage to match local warnings array index.
            let slicedArray = []
            slicedArray = this.InactiveWarnings.slice((pageNumber * this.InactiveWarningsPerPage), ((pageNumber + 1) * this.InactiveWarningsPerPage))
            return slicedArray
        },
        GetCalendarLocale() {
            if (this.$i18n.locale === 'pt-BR') {
                return {
                    MonthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                    DayNames: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
                }
            } else {
                return {
                    MonthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'Dezember'],
                    DayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
                }
            }
        },
    },
    methods: {
        GetInactiveWarningsDatesAvailable() {
            this.TotalInactiveWarnings = 0 //Total number of warnings available on selected date. This info comes from database.
            this.InactiveWarningsDatesAvailable = [] 
            this.SelectedDate = null
            this.InactiveWarnings =[]
            this.$store.dispatch('database/GetInactiveWarningsDatesAvailable', this.GetCurrentUser.activeFlag+'/'+this.GetSelectedCam) //Dispatch action to fetch dates.
            .then(snapshot => {
                let warningDatesArray = [] //Create empty array to store fetched dates and process then before saving to this component's state.
                if (snapshot.exists()) {
                    for (let date in snapshot.val()) { //For each date fetched.
                        let modifiedDate = date.split('-') //Split dates by '-' delimiter to later save them on dd/mm/yyyy format.
                        warningDatesArray.push(new Date(modifiedDate[1] + '/' + modifiedDate[0] + '/' + modifiedDate[2])) //Push date to local array.
                    }
                    warningDatesArray.sort((dateOne, dateTwo) => { return dateOne - dateTwo }) //Sort local array of available dates on chronological order.
                    this.InactiveWarningsDatesAvailable = warningDatesArray //Save local array of available dates on this component's state.
                    this.GetRecentInactiveWarnings() //Call method to get last warnings from database.
                }
            })
        },
        async GetRecentInactiveWarnings() {
            this.AreInactiveWarningsLoading = true
            this.InactiveWarnings = [] //Clear warnings on this component's state.
            this.CurrentPage = 1 //Reset to the first page of pagination.
            this.SelectedDate = this.InactiveWarningsDatesAvailable[this.InactiveWarningsDatesAvailable.length - 1] //Select last date from available dates on this component's state.
            //If warnings on cache are not up to date, dispatch action to get the last warnings from database.
            this.$store.dispatch('database/GetInactiveWarningsUnpaginatedFromDatabase', { date: this.SelectedDate, flag: this.GetCurrentUser.activeFlag+'/'+this.GetSelectedCam, limitToLast: 30 }) //Limited to 15 to match maximum number of warnings visible per page. Doing so allows user to filter 5, 10 or 15 warnings per page properly.
                .then(snapshot => { this.PopulateWarningsArray({ warnings: snapshot, cache: false }) }) //Call method to populate this component's state with warnings from remote.
        },
        PopulateWarningsArray(payload) {
            if (payload.cache) { //Check if payload contains a flag indicating that warnings are from cache.
                this.InactiveWarnings = payload.warnings //Save warnings from cache to this component's state.
            } else { //If payload contains a flag indicating that warnings are from remote.
                let temporaryArray = [] //Create local empty array to store warnings after processing them.
                payload.warnings.forEach(warning => { //For each warning from remote on payload.
                    const tempWarning = warning.val()
                    tempWarning.key = warning.key
                    if (!this.InactiveWarnings.some(item => item.datetime === warning.val().datetime)) temporaryArray.push(tempWarning) //Push warning to local array if it isn't there yet. Done like this because paginated query from Firebase is inclusive, so it fetches at least one repeated alert every page.
                })
                temporaryArray.sort((dateOne, dateTwo) => { return dateTwo.datetime - dateOne.datetime }) //Sort array so warnings are ordered from last to older.
                temporaryArray.forEach(warning => { this.InactiveWarnings.push(warning) }) //Push each warning from local array to this component's state.
            }
            this.UpdateInactiveWarningsCounts()
            // this.CheckWarningsExpectedNumber(this.CurrentPage)
            this.AreInactiveWarningsLoading = false
        },
        UpdateInactiveWarningsCounts() {
            this.$store.dispatch('database/GetInactiveWarningsCountFromDatabase', { date: this.SelectedDate, flag: this.GetCurrentUser.activeFlag+'/'+this.GetSelectedCam })
            .then(snapshot => {
                this.TotalInactiveWarnings = snapshot.val()
            })
        },
        async GetSelectedDateWarnings() {
            this.TotalInactiveWarnings = 0 //Total number of warnings available on selected date. This info comes from database.
            this.InactiveWarnings =[]
            this.AreWarningsLoading = true
            this.Warnings = [] //Clear warnings on this component's state.
            this.$store.dispatch('database/GetInactiveWarningsUnpaginatedFromDatabase', { date: this.SelectedDate, flag: this.GetCurrentUser.activeFlag+'/'+this.GetSelectedCam, limitToLast: 30 }) //Limited to 15 to match maximum number of warnings visible per page. Doing so allows user to filter 5, 10 or 15 warnings per page properly.
                .then(snapshot => { this.PopulateWarningsArray({ warnings: snapshot, cache: false }) }) //Call method to populate this component's state with warnings from remote.
            
        },
    },
}
</script>

<style>

</style>