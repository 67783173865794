<template>
  <div>
    <TheNavbar />
    <div class="box mx-2 my-1 fullHeigth">
      <section>
        <b-tabs
          type="is-toggle-rounded"
          size="is-small"
          position="is-centered"
          expanded
          v-model="componentSelected"
        >
          <!-- Alertas -->
          <b-tab-item label="Alertas" value="warnings" icon="google-photos">
            <div class="container is-flex is-flex-elements-centered">
              <!-- fitlro de cameras -->
              <div>
                <b-field label-position="on-border">
                  <template slot="label">
                    <span class="has-text-primary is-family-sans-serif"
                      >Filtrar por camera</span
                    >
                  </template>
                  <b-autocomplete
                    class="mr-1 rounder"
                    icon="camera"
                    v-model="filteredCam"
                    :data="GetCameras"
                    :open-on-focus="true"
                    :allow-new="false"
                    placeholder="Todas as cameras"
                    clearable
                    @select="(option) => (selected = option)"
                  >
                    <template #empty>No results found</template>
                  </b-autocomplete>
                </b-field>
              </div>

              <!-- filtro de like/dislike-->
              <div>
                <b-field label-position="on-border">
                  <template slot="label">
                    <span class="has-text-primary is-family-sans-serif"
                      >Filtrar por avaliação</span
                    >
                  </template>
                  <b-autocomplete
                    class="mr-1 rounder"
                    icon="thumbs-up"
                    v-model="filteredClassified"
                    :data="warningsStatusAvailable"
                    :open-on-focus="true"
                    :allow-new="false"
                    placeholder="Todas as avaliações"
                    clearable
                    @select="(option) => (selected = option)"
                  >
                    <template #empty>No results found</template>
                  </b-autocomplete>
                </b-field>
              </div>

              <!-- Alertas por pagina -->
              <div class="mr-2">
                <b-field label-position="on-border">
                  <b-dropdown v-model="WarningsPerPage" aria-role="list">
                    <b-button slot="trigger" slot-scope="{ active }">
                      <template>
                        <span>Alertas por página ({{ WarningsPerPage }})</span>
                        <b-icon
                          :icon="active ? 'arrow-up' : 'arrow-down'"
                          size="small"
                        ></b-icon>
                      </template>
                    </b-button>
                    <b-dropdown-item
                      :value="6"
                      aria-role="listitem"
                      class="has-text-left"
                    >
                      6
                    </b-dropdown-item>
                    <b-dropdown-item
                      :value="10"
                      aria-role="listitem"
                      class="has-text-left"
                    >
                      10
                    </b-dropdown-item>
                    <b-dropdown-item
                      :value="16"
                      aria-role="listitem"
                      class="has-text-left"
                    >
                      16
                    </b-dropdown-item>
                    <b-dropdown-item
                      :value="20"
                      aria-role="listitem"
                      class="has-text-left"
                    >
                      20
                    </b-dropdown-item>
                    <b-dropdown-item
                      :value="26"
                      aria-role="listitem"
                      class="has-text-left"
                    >
                      26
                    </b-dropdown-item>
                    <b-dropdown-item
                      :value="30"
                      aria-role="listitem"
                      class="has-text-left"
                    >
                      30
                    </b-dropdown-item>
                  </b-dropdown>
                </b-field>
              </div>

              <!-- Calendario, botão confirmar e refresh -->
              <b-field
                label-position="on-border"
                grouped
                group-multiline
                position="is-centered"
              >
                <template slot="label">
                  <span class="has-text-primary is-family-sans-serif">{{
                    $t("warnings.calendar.label")
                  }}</span>
                </template>

                <!-- Calendario -->
                <b-datepicker
                  class="rounder"
                  :locale="this.$i18n.locale"
                  v-model="SelectedDate"
                  :month-names="GetCalendarLocale.MonthNames"
                  :day-names="GetCalendarLocale.DayNames"
                  :selectable-dates="WarningsDatesAvailable"
                  icon="calendar-alt"
                  range
                  trap-focus
                />

                <!-- Botão Confirmar -->
                <p class="control">
                  <b-button
                    class="button is-primary"
                    @click="
                      GetSelectedDateWarnings({
                        stayOnSamePage: false,
                        ignoreCacheCheck: true,
                      })
                    "
                    expanded
                    ><span class="is-family-sans-serif">{{
                      $t("warnings.calendar.button")
                    }}</span></b-button
                  >
                </p>

                <!-- Botão refresh -->
                <p class="control">
                  <b-button class="button is-primary" @click="refresh" expanded>
                    <b-icon icon="sync" size="is-small"></b-icon>
                  </b-button>
                </p>
              </b-field>
            </div>
            <br />

            <b-loading
              :is-full-page="false"
              :can-cancel="false"
              v-model="AreWarningsLoading"
            ></b-loading>
            <div class="container is-centered" v-if="Warnings.length > 0">
              <b-pagination
                :simple="true"
                :total="TotalWarnings"
                :current.sync="CurrentPage"
                :per-page="WarningsPerPage"
                order="is-centered"
              >
              </b-pagination>
            </div>
            <div class="columns is-multiline mt-4">
              <MordorWarnings
                v-for="alert in GetCurrentPageWarnings"
                :key="alert.datetime"
                :WarningObject="alert"
                class="column is-6"
                v-on:UnseenWarningRemoved="
                  GetSelectedDateWarnings({
                    stayOnSamePage: true,
                    ignoreCacheCheck: true,
                  })
                "
                v-on:SeenWarningRemoved="SeenWarningRemovedHandler()"
                v-on:WarningSeen="TotalSeenWarnings += 1"
              />
            </div>
            <div class="container box" v-if="Warnings.length > 0">
              <b-pagination
                :simple="true"
                :total="TotalWarnings"
                :current.sync="CurrentPage"
                :per-page="WarningsPerPage"
                order="is-centered"
              >
              </b-pagination>
            </div>
          </b-tab-item>

          <!-- Transmissão -->
          <b-tab-item
            v-if="GetCurrentUser.activeFlagLivestream"
            label="Transmissão"
            value="livestream"
          >
            <MordorStream ref="mordorCam" />
          </b-tab-item>

          <!-- Dashboard -->
          <b-tab-item label="Resumo" value="dashboard">
            <MordorDashboard2 ref="dashboard" />
          </b-tab-item>

          <!-- Gravação -->
          <b-tab-item label="Gravação" value="recording">
            <RequestRecording />
          </b-tab-item>

          <!-- Alertas removidos -->
          <b-tab-item
            v-if="false"
            label="Alertas removidos"
            value="warnings-inactive"
          >
            <MordorInactiveWarnings ref="warningsInactive" />
          </b-tab-item>
        </b-tabs>
      </section>
    </div>
    <footer class="box mx-2 footerLogo">
      <img
        v-if="GetCurrentUser.activeFlag.toLowerCase() === 'transpetro'"
        class="logo"
        src="@/assets/transpetro_logo.jpg"
        alt="transpetro"
      />
      <img
        v-else-if="GetCurrentUser.activeFlag.toLowerCase() === 'sabesp'"
        class="logo-sabesp"
        src="@/assets/sabesp_vertical.png"
        alt="sabesp"
      />
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.rounder {
  width: 22vh;
}
.is-flex-elements-centered {
  align-items: center;
  justify-content: center;
}
b-field {
  align-items: center;
}
.footerLogo {
  display: block;
  height: auto;
}
.logo {
  width: 35vh;
  margin-right: 0vh;
}
.logo-sabesp {
  width: 10vh;
  margin-right: 0vh;
}
</style>
<script>
import TheNavbar from "@/components/global/TheNavbar.vue";
import MordorWarnings from "@/components/mordor_camControl/MordorWarnings.vue";
import MordorInactiveWarnings from "@/components/mordor_camControl/MordorInactiveWarnings.vue";
import MordorStream from "@/components/mordor_camControl/MordorStream.vue";
import MordorDashboard2 from "@/components/mordor_camControl/MordorDashboard2.vue";
import RequestRecording from "@/components/mordor/RequestRecording.vue";
import firebase from "../../firebase.config";

export default {
  name: "Mordor",
  components: {
    TheNavbar,
    MordorWarnings,
    MordorStream,
    RequestRecording,
    MordorDashboard2,
    MordorInactiveWarnings,
  },
  data() {
    return {
      CurrentPage: 1, //Bound to current page of pagination component.
      WarningsPerPage: 6,
      TotalWarnings: 0, //Total number of warnings available on selected date. This info comes from database.
      TotalFilteredWarnings: 0,
      WarningsFiltersAvailable: [],
      camSelected: "cam1",
      componentSelected: "warnings",

      WarningsDatesAvailable: [],
      WarningsDatesAvailablePerCam: {},
      SelectedDate: [],
      Warnings: [],
      LastWarning: {},
      TotalWarningsPerCam: {},
      notificationPatrol: 120000,
      notificationStream: 120000,
      defaultTimers: 120000,
      timeStartStream: 0,
      showSnackPatrol: true,
      showSnackStream: true,
      startPage: {}, // control the function showSnackNewWarning. The propertys are add in the function GetWarningsDatesAvailable for each camera avaible
      AreWarningsLoading: true,
      filteredCam: "",
      totalLastDates: -3,
      warningsStatusAvailable: ["Sem avaliação", "Aprovados", "Desaprovados"],
      filteredClassified: "",
    };
  },
  computed: {
    GetCurrentUser() {
      return this.$store.getters["user/GetCurrentUser"];
    },
    //Return slice of local array of warnings based on page user is right now.
    GetCurrentPageWarnings() {
      let pageNumber = this.CurrentPage - 1; //Decrease 1 on CurrentPage to match local warnings array index.
      let slicedArray = [];
      slicedArray = this.Warnings.slice(
        pageNumber * this.WarningsPerPage,
        (pageNumber + 1) * this.WarningsPerPage
      );
      return slicedArray;
    },
    //Return strings used on datepicker based on current locale.
    GetCalendarLocale() {
      if (this.$i18n.locale === "pt-BR") {
        return {
          MonthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ],
          DayNames: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        };
      } else {
        return {
          MonthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "Dezember",
          ],
          DayNames: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        };
      }
    },
    GetLocaleChange() {
      return this.$i18n.locale;
    },
    GetSelectedCam() {
      return this.$store.getters["database/GetCurrentCamera"];
    },
    GetCameras() {
      return this.$store.getters["database/GetCameras"];
    },
    GetUserActiveFlag() {
      return this.$store.getters["user/GetUserActiveFlag"];
    },
  },
  watch: {
    componentSelected: {
      handler(x) {
        switch (x) {
          case "livestream":
            console.log("livestream");
            this.$refs.mordorCam.CheckStatus(true);
            // this.CheckRefresh(false)
            break;
          case "warnings":
            console.log("warnings");
            this.setFirebaseRefToCountOff();
            this.GetWarningsDatesAvailable(); //Call method to get last warnings from database as soon as user object is ready.
            this.GetSelectedDateAvailableFilters();
            this.$refs.mordorCam.CheckStatus(false);
            this.$refs.mordorCam.CloseStreaming();

            // this.CheckRefresh(true)
            break;
          case "dashboard":
            console.log("dashboard");
            this.$refs.dashboard.verifyStatus();
            this.$refs.dashboard.FindLastWatches();
            this.$refs.dashboard.FindRequests();
            this.$refs.mordorCam.CloseStreaming();

            break;
          case "warnings-inactive":
            console.log("warnings-inactive");
            this.$refs.warningsInactive.GetInactiveWarningsDatesAvailable();
            break;

          default:
            this.$refs.mordorCam.CloseStreaming();

            break;
        }
      },
    },
    GetCurrentUser: {
      handler() {
        this.SelectedDateOcyan = "";
        this.flagType = this.GetCurrentUser.flag.find(
          (hirer) => hirer.name === this.GetCurrentUser.activeFlag
        );

        this.Warnings = []; //Empty local warnings array so warnings from one operation don't mix with another.
        this.setFirebaseRefToCountOff();
        this.GetWarningsDatesAvailable(); //Call method to check on dates available for the new operation selected.
        this.GetSelectedDateAvailableFilters();
      },
      deep: true, //Watch for changes on the entire user object, not just its first level.
    },
    //Watch for changes on current page of warnings pagination. Used to detect when user changes page to dispatch action to fetch a new batch of warnings from database.
    CurrentPage: {
      handler(currentPage) {
        console.log("change page");
        this.CheckWarningsExpectedNumber(currentPage);
      },
    },
    WarningsPerPage: {
      handler() {
        this.CheckWarningsExpectedNumber(this.CurrentPage);
      },
    },
    filteredCam: {
      handler() {
        this.TotalWarnings = 0;
        this.GetWarningsDatesAvailable();
      },
    },
    filteredClassified: {
      handler() {
        this.TotalWarnings = 0;
        this.GetWarningsDatesAvailable();
      },
    },
    GetUserActiveFlag: {
      handler() {
        this.ResetData();
        this.GetWarningsDatesAvailable();
        this.checkStreamingAndPatrolStatus();
      },
      deep: true,
    },
  },
  methods: {
    async refresh() {
      if (this.filteredCam.length || this.filteredClassified.length) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms));
        this.filteredCam = "";
        await delay(1000);
        this.filteredClassified = "";
      } else {
        this.TotalWarnings = 0;
        this.setFirebaseRefToCountOff();
        this.GetWarningsDatesAvailable();
      }
    },

    //Used to fetch from database all dates which have warnings. Dates fetched here are displayed on the datepicker component.
    async GetWarningsDatesAvailablePerCam(cameraArray) {
      let warningDatesArray = []; //Create empty array to store fetched dates and process then before saving to this component's state.
      let warningDatesArrayUnique = [];
      if (
        !this.GetCameras.some(
          (cam) => cam.toLowerCase() === this.filteredCam.toLowerCase()
        )
      ) {
        for (let i in cameraArray) {
          this.WarningsDatesAvailablePerCam[cameraArray[i]] = new Set(); // Create a array for all cameras
          const snapshot = await this.$store.dispatch(
            "database/GetWarningsDatesAvailable",
            this.GetCurrentUser.activeFlag + "/" + cameraArray[i]
          );
          for (let date in snapshot.val()) {
            this.WarningsDatesAvailablePerCam[cameraArray[i]].add(date);
            let modifiedDate = date.split("-");
            warningDatesArray.push(
              new Date(
                modifiedDate[1] + "/" + modifiedDate[0] + "/" + modifiedDate[2]
              )
            );
            warningDatesArray.sort((dateOne, dateTwo) => {
              return dateOne - dateTwo;
            });
            warningDatesArrayUnique = warningDatesArray
              .map(function (date) {
                return date.getTime();
              })
              .filter(function (date, i, array) {
                return array.indexOf(date) === i;
              })
              .map(function (time) {
                return new Date(time);
              });

            this.WarningsDatesAvailable = warningDatesArrayUnique; //Save local array of available dates on this component's state.
            this.TotalWarningsPerCam[cameraArray[i]] = {};
          }
        }
      }
    },
    async GetWarningsDatesAvailable() {
      let cameraArray = Array.from(this.GetCameras);
      console.log(cameraArray);
      await this.GetWarningsDatesAvailablePerCam(cameraArray);
      let initialDate;
      this.WarningsDatesAvailable.length >= 3
        ? (initialDate =
            this.WarningsDatesAvailable[this.WarningsDatesAvailable.length - 3])
        : (initialDate =
            this.WarningsDatesAvailable[
              this.WarningsDatesAvailable.length - 1
            ]);
      let finalDate =
        this.WarningsDatesAvailable[this.WarningsDatesAvailable.length - 1];
      this.SelectedDate = [initialDate, finalDate];
      let searchDates = this.WarningsDatesAvailable.slice(-3);

      if (
        !this.GetCameras.some(
          (cam) => cam.toLowerCase() === this.filteredCam.toLowerCase()
        )
      ) {
        for (let date in searchDates) {
          for (let cam in cameraArray) {
            this.GetRecentWarnings(cameraArray[cam], searchDates[date]); //Call method to get last warnings from database.
            this.startPage[cameraArray[cam]] = true;
            this.CheckNewWarnings(true, cameraArray[cam], this.startPage);
          }
        }
      } else {
        this.GetCameras.forEach((camera) => {
          this.TotalWarningsPerCam[camera] = {};
        });
        this.setFirebaseRefToCountOff();

        const snapshot = await this.$store.dispatch(
          "database/GetWarningsDatesAvailable",
          this.GetCurrentUser.activeFlag + "/" + this.filteredCam
        );
        let warningDatesArray = [];

        for (let date in snapshot.val()) {
          let modifiedDate = date.split("-");
          warningDatesArray.push(
            new Date(
              modifiedDate[1] + "/" + modifiedDate[0] + "/" + modifiedDate[2]
            )
          );
        }
        warningDatesArray.sort((dateOne, dateTwo) => {
          return dateOne - dateTwo;
        });

        this.WarningsDatesAvailable = warningDatesArray; //Save local array of available dates on this component's state.
        this.SelectedDate = [];
        this.SelectedDate.push(
          this.WarningsDatesAvailable[this.WarningsDatesAvailable.length - 1]
        );

        this.GetRecentWarnings(this.filteredCam, this.SelectedDate[0]); //Call method to get last warnings from database.
        this.CheckNewWarnings(false);
      }
    },
    //Used to check if warnings from cache are up to date with warnings from remote.
    GetWarningsCacheStatus() {
      let lastWarningFromCache =
        this.$store.getters["database/GetWarningsCache"][0]; //Get last warning from cache.
      if (lastWarningFromCache) {
        //Check if there is actually a warning on cache.
        return this.$store
          .dispatch("database/GetWarningsUnpaginatedFromDatabase", {
            date: this.SelectedDate,
            flag: this.GetCurrentUser.activeFlag + "/" + this.GetSelectedCam,
            limitToLast: 1,
          })
          .then((snapshot) => {
            let cachedWarningDatetime = lastWarningFromCache.datetime; //Save datetime of warning from cache.
            let remoteWarningDatetime = null; //Create variable to save datetime of warning from remote.
            snapshot.forEach((childSnapshot) => {
              remoteWarningDatetime = childSnapshot.val().datetime;
            }); //Iterate over waninng fetched from remote.
            return remoteWarningDatetime === cachedWarningDatetime
              ? true
              : false; //Return true or false whether warning from cache is up to date with remote or not.
          });
      } else {
        return false; //Return false when there is no cache.
      }
    },
    //Populate this component's state with either warnings from cache or from remote.
    //Payload: { warnings: warningArray, cache: true/false }
    PopulateWarningsArray(payload) {
      if (payload.cache) {
        //Check if payload contains a flag indicating that warnings are from cache.
        this.Warnings = payload.warnings; //Save warnings from cache to this component's state.
      } else {
        //If payload contains a flag indicating that warnings are from remote.
        let temporaryArray = []; //Create local empty array to store warnings after processing them.
        payload.warnings.forEach((warning) => {
          //For each warning from remote on payload.
          const tempWarning = warning.val();
          tempWarning.key = warning.key;
          tempWarning.camera = payload.camera;
          if (
            !this.Warnings.some(
              (item) => item.datetime === warning.val().datetime
            )
          ) {
            // Check if has a filter by warning status
            if (!this.filteredClassified) {
              temporaryArray.push(tempWarning); //Push warning to local array if it isn't there yet. Done like this because paginated query from Firebase is inclusive, so it fetches at least one repeated alert every page.
              return;
            }
            if (this.filteredClassified == "Aprovados") {
              if (tempWarning.thumbsUp) temporaryArray.push(tempWarning);
              this.ShowingFilteredWarnings = true;
              return;
            }
            if (this.filteredClassified == "Desaprovados") {
              if (tempWarning.thumbsDown) temporaryArray.push(tempWarning);
              this.ShowingFilteredWarnings = true;
              return;
            }
            if (this.filteredClassified == "Sem avaliação") {
              if (!tempWarning.thumbsDown && !tempWarning.thumbsUp)
                temporaryArray.push(tempWarning);
              this.ShowingFilteredWarnings = true;
              return;
            }
          }
        });
        if (temporaryArray.length) {
          temporaryArray.sort((dateOne, dateTwo) => {
            return dateTwo.datetime - dateOne.datetime;
          }); //Sort array so warnings are ordered from last to older.
          this.LastWarning[payload.camera] =
            temporaryArray[temporaryArray.length - 1].datetime;
          temporaryArray.forEach((warning) => {
            this.Warnings.push(warning);
          }); //Push each warning from local array to this component's state.
          this.Warnings.sort((dateOne, dateTwo) => {
            return dateTwo.datetime - dateOne.datetime;
          }); //Sort array so warnings are ordered from last to older.
          this.$store.dispatch("database/SetWarningsCache", this.Warnings); //Dispatch action to store to save current warnings on this component's state to cache.)
        }
      }
      if (!payload.isFromCheckWarnings) {
        this.UpdateWarningsCounts(payload.camera, payload.date);
        this.CheckWarningsExpectedNumber(this.CurrentPage);
      }
      this.AreWarningsLoading = false;
    },
    //Used to fetch last warnings from database. Async because it needs to wait for a check on warnings cache, done by a method that returns a promise.

    async GetRecentWarnings(camera, date) {
      this.AreWarningsLoading = true;
      this.SelectedFilter = null;
      this.ShowingFilteredWarnings = false;
      this.Warnings = []; //Clear warnings on this component's state.
      this.CurrentPage = 1; //Reset to the first page of pagination.
      //If warnings on cache are not up to date, dispatch action to get the last warnings from database.
      let warningsSnapshot = await this.$store.dispatch(
        "database/GetWarningsUnpaginatedFromDatabase",
        {
          date: date,
          flag: this.GetCurrentUser.activeFlag + "/" + camera,
          limitToLast: 30,
        }
      ); //Limited to 15 to match maximum number of warnings visible per page. Doing so allows user to filter 5, 10 or 15 warnings per page properly.
      return this.PopulateWarningsArray({
        warnings: warningsSnapshot,
        cache: false,
        camera: camera,
        date: date,
      }); //Call method to populate this component's state with warnings from remote.
    },
    //Used to fetch warnings from database on a specific date issued by user on datepicker component. Async because it needs to wait for a check on warnings cache, done by a method that returns a promise.
    async GetSelectedDateWarnings(options) {
      const datesGetTimeArray = this.WarningsDatesAvailable.map((e) =>
        e.getTime()
      );
      this.TotalWarnings = 0;
      this.AreWarningsLoading = true;
      this.SelectedFilter = null;
      this.ShowingFilteredWarnings = false;
      this.Warnings = []; //Clear warnings on this component's state.
      let datesSearch = [];
      if (this.SelectedDate.length > 1) {
        let initialDateIdx = datesGetTimeArray.indexOf(
          this.SelectedDate[0].getTime()
        );
        let finalDateIdx = datesGetTimeArray.indexOf(
          this.SelectedDate[1].getTime()
        );
        initialDateIdx == finalDateIdx
          ? datesSearch.push(this.SelectedDate[0])
          : (datesSearch = this.WarningsDatesAvailable.slice(
              initialDateIdx,
              finalDateIdx + 1
            ));
      } else {
        datesSearch.push(this.SelectedDate[0]);
      }

      if (!options.stayOnSamePage) this.CurrentPage = 1; //Reset to the first page of pagination if option passed as parameter.
      this.$store.dispatch("database/SetWarningsCache", []); //Clear previous warnings cache.
      if (
        !this.GetCameras.some(
          (cam) => cam.toLowerCase() === this.filteredCam.toLowerCase()
        )
      ) {
        this.GetCameras.forEach((camera) => {
          for (let date in datesSearch) {
            this.$store
              .dispatch("database/GetWarningsUnpaginatedFromDatabase", {
                date: datesSearch[date],
                flag: this.GetCurrentUser.activeFlag + "/" + camera,
                limitToLast: 30,
              }) //Limited to 15 to match maximum number of warnings visible per page. Doing so allows user to filter 5, 10 or 15 warnings per page properly.
              .then((snapshot) => {
                this.PopulateWarningsArray({
                  warnings: snapshot,
                  cache: false,
                  camera: camera,
                  date: datesSearch[date],
                });
              }); //Call method to populate this component's state with warnings from remote.
          }
        });
      } else {
        for (let date in datesSearch) {
          this.$store
            .dispatch("database/GetWarningsUnpaginatedFromDatabase", {
              date: datesSearch[date],
              flag: this.GetCurrentUser.activeFlag + "/" + this.filteredCam,
              limitToLast: 30,
            }) //Limited to 15 to match maximum number of warnings visible per page. Doing so allows user to filter 5, 10 or 15 warnings per page properly.
            .then((snapshot) => {
              this.PopulateWarningsArray({
                warnings: snapshot,
                cache: false,
                camera: this.filteredCam,
                date: datesSearch[date],
              });
            });
        }
      }
    },
    CheckWarningsExpectedNumber(currentPage) {
      if (!this.Warnings.length) return;

      let expectedWarnings = currentPage * this.WarningsPerPage; //Total warnings expected to be on local array on this page.

      if (
        this.Warnings.length < expectedWarnings &&
        this.Warnings.length < this.TotalWarnings
      ) {
        //Check if local warnings array is smaller than it should be on this page.
        this.AreWarningsLoading = true;
        if (
          !this.GetCameras.some(
            (cam) => cam.toLowerCase() === this.filteredCam.toLowerCase()
          )
        ) {
          this.GetCameras.forEach((camera) => {
            this.$store
              .dispatch("database/GetWarningsPaginatedFromDatabase", {
                date: this.LastWarning[camera],
                flag: this.GetCurrentUser.activeFlag + "/" + camera,
                limitToLast: this.WarningsPerPage + 1,
              }) //Limited to 15 to match maximum number of warnings visible per page. Doing so allows user to filter 5, 10 or 15 warnings per page properly.
              .then((snapshot) => {
                if (snapshot.exists())
                  this.PopulateWarningsArray({
                    warnings: snapshot,
                    cache: false,
                    camera: camera,
                    isFromCheckWarnings: true,
                  });
              }); //Call method to populate this component's state with warnings from remote.
          });
        } else {
          let warningDatetime =
            this.Warnings[this.Warnings.length - 1].datetime;
          this.$store
            .dispatch("database/GetWarningsPaginatedFromDatabase", {
              date: warningDatetime,
              flag: this.GetCurrentUser.activeFlag + "/" + this.filteredCam,
              limitToLast: this.WarningsPerPage + 1,
            })
            .then((snapshot) => {
              this.PopulateWarningsArray({
                warnings: snapshot,
                cache: false,
                camera: this.filteredCam,
                isFromCheckWarnings: true,
              });
            });
        }
      }
    },
    UpdateWarningsCounts(camera, date) {
      const dateString = date.toLocaleDateString("pt-BR").split("/").join("-");

      if (!this.ShowingFilteredWarnings) {
        if (camera != "camx") {
          this.$store
            .dispatch("database/GetWarningsCountFromDatabase", {
              date: date,
              flag: this.GetCurrentUser.activeFlag + "/" + camera,
            })
            .then((snapshot) => {
              if (snapshot.exists()) {
                this.TotalWarnings += snapshot.val();
                this.TotalWarningsPerCam[camera][dateString] = snapshot.val();
              }
              return this.$store.dispatch(
                "database/GetSeenWarningsCountFromDatabase",
                {
                  dateString: date
                    .toLocaleDateString("pt-BR")
                    .split("/")
                    .join("-"),
                  flag: this.GetCurrentUser.activeFlag + "/" + camera,
                }
              );
            })
            .then((snapshot) => {
              if (snapshot.exists()) this.TotalSeenWarnings = snapshot.val();
            });
        }
      } else {
        this.TotalWarnings = this.Warnings.length;
      }
    },
    SeenWarningRemovedHandler() {
      this.$store
        .dispatch("database/GetSeenWarningsCountFromDatabase", {
          dateString: this.SelectedDate.toLocaleDateString("pt-BR")
            .split("/")
            .join("-"),
          flag: this.GetCurrentUser.activeFlag + "/" + this.GetSelectedCam,
        })
        .then((snapshot) => {
          this.TotalSeenWarnings = snapshot.val() - 1;
          this.$store.dispatch("database/SetSeenWarningsCountOnDatabase", {
            dateString: this.SelectedDate.toLocaleDateString("pt-BR")
              .split("/")
              .join("-"),
            flag: this.GetCurrentUser.activeFlag + "/" + this.GetSelectedCam,
            seenWarnings: this.TotalSeenWarnings,
          });
          // .then(() => { this.GetSelectedDateWarnings({ stayOnSamePage: true, ignoreCacheCheck: true }) })
        });
    },
    GetSelectedDateAvailableFilters() {
      console.log("GetSelectedDateAvailableFilters");

      this.WarningsFiltersAvailable = [];
      while (this.GetCurrentUser.activeFlag == undefined) {
        this.GetSelectedDateAvailableFilters();
      }
      this.$store
        .dispatch("database/GetWarningsFiltersAvailable", {
          date: this.SelectedDate,
          flag: this.GetCurrentUser.activeFlag + "/" + this.GetSelectedCam,
        })
        .then((snapshot) => {
          if (snapshot.exists())
            this.WarningsFiltersAvailable = Object.keys(snapshot.val());
        });
    },
    GetSelectedDateFilteredWarnings(preset) {
      return this.$store
        .dispatch("database/GetWarningsPresetFilteredFromDatabase", {
          date: this.SelectedDate,
          flag: this.GetCurrentUser.activeFlag,
          limitToLast: 30,
          presetID: preset,
        })
        .then((snapshot) => {
          if (snapshot.exists()) {
            this.Warnings = [];
            this.ShowingFilteredWarnings = true;
            this.PopulateWarningsArray({ warnings: snapshot, cache: false });
          } else {
            this.LaunchToast("Não há alertas com esse filtro.", "is-danger");
            this.SelectedFilter = null;
          }
        });
    },

    LaunchToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        type: type,
        position: "is-bottom",
        duration: 3000,
      });
    },
    CheckNewWarnings(status, camera) {
      const ref = firebase
        .database()
        .ref(
          "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/" +
            camera +
            "/count/"
        );
      if (status) {
        ref.on("value", (snapshot) => {
          if (
            snapshot.numChildren() ==
            this.WarningsDatesAvailablePerCam[camera].size
          ) {
            if (this.startPage[camera]) {
              this.startPage[camera] = false;
            } else {
              for (const date in this.TotalWarningsPerCam[camera]) {
                let newCount = snapshot.val()[date.replaceAll("/", "-")];
                if (newCount > this.TotalWarningsPerCam[camera][date]) {
                  this.LaunchSnackNewWarnings(
                    "Novo alerta recebido",
                    "is-success",
                    true
                  );
                  this.setFirebaseRefToCountOff();
                  this.TotalWarnings = 0;
                  this.GetWarningsDatesAvailable();
                } else if (newCount < this.TotalWarningsPerCam[camera][date]) {
                  this.LaunchSnackNewWarnings(
                    "Um alerta foi removido",
                    "is-warning",
                    false
                  );
                  this.setFirebaseRefToCountOff();
                  this.TotalWarnings = 0;
                  this.GetWarningsDatesAvailable();
                  break;
                }
              }
            }
          } else if (
            snapshot.numChildren() <
            this.WarningsDatesAvailablePerCam[camera].size
          ) {
            this.LaunchSnackNewWarnings(
              "Um alerta foi removido",
              "is-warning",
              false
            );
            this.setFirebaseRefToCountOff();
            this.TotalWarnings = 0;
            this.GetWarningsDatesAvailable();
          } else if (
            snapshot.numChildren() >
            this.WarningsDatesAvailablePerCam[camera].size
          ) {
            this.LaunchSnackNewWarnings(
              "Novo alerta recebido",
              "is-success",
              true
            );
            this.setFirebaseRefToCountOff();
            this.TotalWarnings = 0;
            this.GetWarningsDatesAvailable();
          }
        });
      } else {
        ref.off();
      }
    },
    setFirebaseRefToCountOff() {
      firebase
        .database()
        .ref(
          "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/cam1/count"
        )
        .off();
      firebase
        .database()
        .ref(
          "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/cam2/count"
        )
        .off();
      firebase
        .database()
        .ref(
          "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/cam3/count"
        )
        .off();
    },
    LaunchSnackNewWarnings(message, type, indefinite) {
      this.$buefy.snackbar.open({
        indefinite: indefinite,
        message: message,
        type: type,
        position: "is-top",
      });
    },
    LaunchSnackPatrol(cam, oldTime) {
      setTimeout(() => {
        this.$store
          .dispatch("database/GetFromDatabase", {
            path:
              "sauron/" +
              this.GetCurrentUser.activeFlag.toLowerCase() +
              "/config/state/ronda/" +
              cam,
          })
          .then((snp) => {
            this.showSnackPatrol = true;
            if (oldTime != snp.val()) {
              console.log(Date.now() - snp.val());
            } else {
              this.startPatrol(cam);
              this.$buefy.toast.open({
                message: "A ronda da " + cam + " foi ativada automaticamente.",
                queue: false,
              });
            }
          });
      }, 21000);
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/" +
            cam +
            "/patrol",
        })
        .then((snp) => {
          if (this.showSnackPatrol && snp.val() == 0) {
            this.showSnackPatrol = false;
            this.$buefy.snackbar.open({
              message:
                "Atenção! A ronda da " +
                cam +
                " está desativada a mais de " +
                Math.round(this.notificationPatrol / 60000) +
                " minutos.",
              type: "is-warning",
              position: "is-top",
              actionText: "Manter inativa",
              indefinite: false,
              queue: true,
              duration: 20000,
              onAction: () => {
                this.showSnackPatrol = !this.showSnackPatrol;
                this.$store
                  .dispatch("database/GetFromDatabase", {
                    path:
                      "sauron/" +
                      this.GetCurrentUser.activeFlag.toLowerCase() +
                      "/camcontrol/" +
                      cam +
                      "/patrol",
                  })
                  .then((snp) => {
                    if (snp.val() == 0) {
                      this.$store
                        .dispatch("database/SetOnDatabase", {
                          path:
                            "sauron/" +
                            this.GetCurrentUser.activeFlag.toLowerCase() +
                            "/config/state/ronda/" +
                            cam,
                          object: Date.now(),
                        })
                        .then(() => {
                          this.$buefy.toast.open({
                            message: "A ronda permanecerá desativada.",
                            queue: true,
                          });
                        });
                    }
                  });
              },
            });
          }
        });
    },
    LaunchSnackStream(cam, oldTime) {
      setTimeout(() => {
        this.$store
          .dispatch("database/GetFromDatabase", {
            path:
              "sauron/" +
              this.GetCurrentUser.activeFlag.toLowerCase() +
              "/config/state/streaming/" +
              cam,
          })
          .then((snp) => {
            this.$store
              .dispatch("database/GetFromDatabase", {
                path:
                  "sauron/" +
                  this.GetCurrentUser.activeFlag.toLowerCase() +
                  "/camcontrol/" +
                  cam +
                  "/watching/",
              })
              .then((user) => {
                if (oldTime != snp.val() && snp.val() != 0) {
                  this.showSnackStream = true;
                } else {
                  if (
                    this.componentSelected == "livestream" &&
                    cam == this.GetSelectedCam &&
                    Object.values(user.val()).includes(
                      this.GetCurrentUser.login
                    )
                  ) {
                    // this.showSnackStream=true
                    this.componentSelected = "warning";
                  }
                  this.stopStream(cam);
                  this.showSnackStream = true;
                }
              });
          });
      }, 16000);
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/" +
            cam +
            "/streaming",
        })
        .then((snp) => {
          if (this.showSnackStream && snp.val() > 0) {
            this.showSnackStream = false;
            this.$buefy.snackbar.open({
              message:
                "Atenção! A transmissão da " +
                cam +
                " esta aberta a mais de " +
                Math.round(this.notificationStream / 60000) +
                " minutos.",
              type: "is-warning",
              position: "is-top",
              actionText: "Manter transmissão",
              queue: false,
              duration: 17000,
              onAction: () => {
                this.showSnackStream = !this.showSnackStream;
                this.$store
                  .dispatch("database/SetOnDatabase", {
                    path:
                      "sauron/" +
                      this.GetCurrentUser.activeFlag.toLowerCase() +
                      "/config/state/streaming/" +
                      cam,
                    object: Date.now(),
                  })
                  .then(() => {
                    this.showSnackStream = true;
                    this.$buefy.toast.open({
                      message: "A transmissão permanecerá aberta.",
                      queue: false,
                    });
                  });
              },
            });
          }
        });
    },
    AddZero(number) {
      number < 10 ? (number = "0" + number) : number;
      number.length > 2 ? (number = number.substring(1)) : number;
      return number.toString();
    },
    isValidInt(value) {
      let parsed = parseInt(value);
      if (!isNaN(parsed)) {
        return parsed;
      }
      return this.defaultTimers;
    },
    timer() {
      clearInterval(this.counter);
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "/sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/config",
          returnSnapshotVal: true,
        })
        .then((timers) => {
          try {
            if (timers.notificationPatrol) {
              this.notificationPatrol = this.isValidInt(
                timers.notificationPatrol
              );
            }
            if (timers.notificationStream) {
              this.notificationStream = this.isValidInt(
                timers.notificationStream
              );
            }
            if (timers.refreshTime) {
              this.refreshTime = this.isValidInt(timers.refreshTime);
            }
          } catch (error) {
            console.log(error);
          }
        })

        .catch(() => {
          this.notificationPatrol = 120000;
          this.notificationStream = 120000;
        });
    },
    checkStreamingAndPatrolStatus() {
      let ref = firebase
        .database()
        .ref(
          "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/config/state/"
        );
      ref.on("value", (snp) => {
        Object.keys(snp.val().ronda).forEach((cam) => {
          if (snp.val().ronda[cam] != 0) {
            let diferenceMs = 0;
            if (
              parseInt(snp.val().ronda[cam] + this.notificationPatrol) >
              Date.now()
            ) {
              diferenceMs =
                parseInt(snp.val().ronda[cam] + this.notificationPatrol) -
                Date.now(); //pega o tempo em que a ronda foi parada e verifica quanto falta para x tempo, o resultado é usado para o timeout.
            } else {
              diferenceMs = 1000;
            }
            setTimeout(() => {
              this.LaunchSnackPatrol(cam, snp.val().ronda[cam]);
            }, diferenceMs);
          }
        });
        Object.keys(snp.val().streaming).forEach((cam) => {
          if (snp.val().streaming[cam] != 0) {
            if (this.timeStartStream == 0) {
              this.timeStartStream = snp.val().streaming[cam];
            }
            let diferenceMs =
              snp.val().streaming[cam] + this.notificationStream - Date.now(); //pega o tempo em que a streaming foi parada e verifica quanto falta para x tempo, o resultado é usado para o timeout.

            setTimeout(() => {
              this.LaunchSnackStream(cam, snp.val().streaming[cam]);
            }, diferenceMs);
          }
        });
      });
    },
    startPatrol(cam) {
      this.snackbar = true;
      this.$store.dispatch("database/SetOnDatabase", {
        path:
          "sauron/" +
          this.GetCurrentUser.activeFlag.toLowerCase() +
          "/camcontrol/" +
          cam +
          "/presetManual",
        object: parseInt(0),
      });
      this.$store
        .dispatch("database/SetOnDatabase", {
          path:
            "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/" +
            cam +
            "/patrol",
          object: 1,
        })
        .then(() => {
          this.$store.dispatch("database/SetOnDatabase", {
            path:
              "sauron/" +
              this.GetCurrentUser.activeFlag.toLowerCase() +
              "/config/state/ronda/" +
              cam,
            object: 0,
          });
          this.saveDataReportPatrol(cam);
          this.LaunchToast("Ronda ativada.", "is-success");
          this.rondaState = true;
        })
        .catch((error) => {
          this.LaunchToast("Falha ao ativar ronda.", "is-danger");
          console.log(error);
        });
    },
    saveDataReportPatrol(cam) {
      let timestamp = Date.now();
      let date = new Date(timestamp)
        .toLocaleDateString("pt-BR")
        .split("/")
        .join("-");
      this.$store
        .dispatch("database/SetOnDatabase", {
          path:
            "mordor-history/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/ronda/" +
            cam +
            "/" +
            date +
            "/" +
            timestamp,
          object: {
            login: "Auto",
            name: "Auto",
            status: 1,
            date: timestamp,
          },
        })
        .then(() => {
          this.$store.dispatch("database/SetOnDatabase", {
            path:
              "mordor-history/" +
              this.GetCurrentUser.activeFlag.toLowerCase() +
              "/ronda/" +
              cam +
              "/" +
              "/date-index/" +
              date,
            object: true,
          });
        });
    },

    stopStream(cam) {
      //contabilizar o streaming /sauron/transpetro/camcontrol/cam1
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/" +
            cam +
            "/watching",
        })
        .then((watcher) => {
          console.log("watcher");
          Object.keys(watcher.val()).forEach((w) => {
            let payload = { uid: w, email: watcher.val()[w] };
            // this.$store.dispatch("user/Logout", payload); //aqui jaz a idéia de derrubar o usuario que deixa o streaming aberto.
            if (payload.email == this.GetCurrentUser.email) {
              // window.location.reload(true) //aqui a idéia dada pelo jimmy neutro, atualizar a pagina.
              this.$refs.mordorCam.CloseStreaming();
              this.componentSelected = "warning";
            }
          });
        })

        .then(() => {
          this.$store.dispatch("database/SetOnDatabase", {
            path:
              "sauron/" +
              this.GetCurrentUser.activeFlag.toLowerCase() +
              "/config/state/streaming/" +
              cam,
            object: 0,
          });

          this.LaunchToast(
            "A transmissão da " + cam + " foi parada automaticamente.",
            "is-success"
          );
        })
        .catch((error) => {
          // if(this.componentSelected=='livestream'){

          // this.LaunchToast('Falha ao encerrar transmissão.', 'is-danger')

          // }
          console.log(error);
        });
    },
    CloseStreaming(cam) {
      this.$refs.mordorCam.GetImagePresetManual(
        this.$refs.mordorCam.presetSelected
      );
      this.$refs.mordorCam.ToggleLoadings(false);
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/" +
            cam +
            "/streaming",
          returnSnapshotVal: false,
        })
        .then((snapshot) => {
          if (snapshot.val() > 0) {
            return this.$store
              .dispatch("database/SetOnDatabase", {
                path:
                  "sauron/" +
                  this.GetCurrentUser.activeFlag.toLowerCase() +
                  "/camcontrol/" +
                  cam +
                  "/streaming",
                object: 0,
              })
              .then(() => {
                return setTimeout(() => {
                  this.$store.dispatch(
                    "database/RemoveFromDatabase",
                    {
                      path:
                        "sauron/" +
                        this.GetCurrentUser.activeFlag.toLowerCase() +
                        "/camcontrol/" +
                        cam +
                        "/watching",
                    },
                    5000
                  );
                }).then(() => {
                  this.$refs.mordorCam.CancelStreamingOnDisconnectHandler();
                });
              });
          }
        });
      // })
    },
    ResetData() {
      this.CurrentPage = 1;
      (this.WarningsPerPage = 6),
        (this.TotalWarnings = 0),
        (this.TotalFilteredWarnings = 0),
        (this.WarningsFiltersAvailable = []),
        (this.componentSelected = "warnings"),
        (this.WarningsDatesAvailable = []),
        (this.WarningsDatesAvailablePerCam = {}),
        (this.SelectedDate = []),
        (this.Warnings = []),
        (this.LastWarning = {}),
        (this.TotalWarningsPerCam = {}),
        (this.AreWarningsLoading = true),
        (this.filteredCam = ""),
        (this.filteredClassified = "");
    },
  },
  created() {
    const delay = () => new Promise((resolve) => setTimeout(resolve, 3000));

    const awaitDelay = async () => {
      await delay();
      await this.timer();
      this.GetWarningsDatesAvailable();
      this.checkStreamingAndPatrolStatus();
    };

    awaitDelay();
  },
  beforeDestroy() {
    this.setFirebaseRefToCountOff();
  },
};
</script>
