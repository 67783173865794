<template>
  <div class="column">
    <section class="hero">
      <div>
        <b-message type="is-success" size="is-small">
          Transmissão ativa
        </b-message>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "MordorStreamWarning",
};
</script>
