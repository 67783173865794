<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(sendSolicitation)" class="box">
        <div class="column is-half is-offset-one-quarter">
          <section class="title">
            {{ $t("mordor.recRequest") }}
          </section>
          <section class="subtitle">
            <p>
              <b>{{ GetCurrentUser.name }}</b>
            </p>
            <p>
              <b> {{ GetCurrentUser.email }}</b>
            </p>
          </section>
          <div style="text-align: left">
            <b>{{ $t("mordor.recProtocol") }}:</b>{{ requesting.protocol }}
          </div>
          <ValidationProvider rules="required" v-slot="{ errors, valid }">
            <b-field
              label-position="on-border"
              class="mt-5"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
            >
              <template slot="label">
                <span
                  class="has-text-primary is-family-sans-serif has-text-weight-bold"
                  >{{ $t("mordor.recDatepick") }}</span
                >
              </template>
              <b-datepicker
                v-model="requesting.dateSelect"
                :placeholder="$t('mordor.recDatepick')"
                icon="calendar-alt"
                trap-focus
              >
              </b-datepicker>
            </b-field>
          </ValidationProvider>
          <div class="columns mt-1">
            <div class="column">
              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <b-field
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <template slot="label">
                    <span
                      class="has-text-primary is-family-sans-serif has-text-weight-bold"
                      >{{ $t("mordor.recStart") }}</span
                    >
                  </template>
                  <b-timepicker
                    v-model="requesting.startHour"
                    :placeholder="$t('mordor.recStart')"
                    icon="clock"
                    locale="pt-BR"
                  >
                  </b-timepicker>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column">
              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <b-field
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <template slot="label">
                    <span
                      class="has-text-primary is-family-sans-serif has-text-weight-bold"
                      >{{ $t("mordor.recEnd") }}</span
                    >
                  </template>
                  <b-timepicker
                    required
                    v-model="requesting.endHour"
                    :placeholder="$t('mordor.recEnd')"
                    icon="clock"
                    locale="pt-BR"
                  >
                  </b-timepicker>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <ValidationProvider rules="required" v-slot="{ errors, valid }">
            <b-field
              label-position="on-border"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
            >
              <template slot="label">
                <span
                  class="has-text-primary is-family-sans-serif has-text-weight-bold"
                  >{{ $t("mordor.recLocation") }}</span
                >
              </template>
              <b-select
                expanded
                v-model="requesting.localeCam"
                v-if="GetCurrentUser.activeFlag.toLowerCase() == 'transpetro'"
              >
                <option v-for="item in localsTranspetro" :key="item">
                  {{ item }}
                </option>
              </b-select>
              <b-select
                expanded
                v-model="requesting.localeCam"
                v-else-if="GetCurrentUser.activeFlag.toLowerCase() == 'sabesp'"
              >
                <option v-for="item in localsSabesp" :key="item">
                  {{ item }}
                </option>
              </b-select>
              <b-select
                expanded
                v-model="requesting.localeCam"
                v-else-if="
                  GetCurrentUser.activeFlag.toLowerCase().includes('vale')
                "
              >
                <option v-for="item in localsVale" :key="item">
                  {{ item }}
                </option>
              </b-select>
              <b-select
                expanded
                v-model="requesting.localeCam"
                v-else-if="
                  GetCurrentUser.activeFlag.toLowerCase() === 'mrd-01-pqtec'
                "
              >
                <option v-for="item in localsAltave" :key="item">
                  {{ item }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
          <b-field label-position="on-border" class="mt-5">
            <template slot="label">
              <span
                class="has-text-primary is-family-sans-serif has-text-weight-bold"
                >{{ $t("mordor.recObs") }}</span
              >
            </template>
            <b-input v-model="requesting.details" type="textarea"></b-input>
          </b-field>
          <b-field>
            <!-- Label left empty for spacing -->
            <p class="control">
              <b-button
                :label="$t('mordor.recSend')"
                :loading="IsConfirmButtonLoading"
                expanded
                outlined
                native-type="submit"
                :disabled="invalid"
                type="is-primary"
              />
            </p>
          </b-field>
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>
<style lang="scss" scoped></style>
<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", required);

import firebase from "../../../firebase.config.js";
export default {
  name: "RequestRecording",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      requesting: {
        dateSelect: null,
        startHour: null,
        endHour: null,
        details: "",
        message: "",
        protocol: Date.now(),
        localeCam: "",
        requester: "",
        requesterMail: "",
        company: "",
        created: "",
      },
      // locals:['mrd-01-vale','mrd-02-vale'],
      localsVale: ["mrd-01-vale", "mrd-03-vale"],
      localsTranspetro: ["cam1", "cam2", "cam3"],
      localsSabesp: ["cam1"],
      localsAltave: ["mrd-01-pqtec"],
      IsConfirmButtonLoading: false,
    };
  },

  computed: {
    GetCurrentUser() {
      return this.$store.getters["user/GetCurrentUser"];
    },
  },
  methods: {
    resetData() {
      (this.requesting.dateSelect = null),
        (this.requesting.startHour = null),
        (this.requesting.endHour = null),
        (this.requesting.details = ""),
        (this.requesting.localeCam = "");
      this.requesting.protocol = Date.now();
    },
    sendSolicitation() {
      this.IsConfirmButtonLoading = true;
      this.requesting.requester = this.GetCurrentUser.name;
      this.requesting.requesterMail = this.GetCurrentUser.email;
      this.requesting.company = this.GetCurrentUser.activeFlag;
      this.requesting.startHour = new Date(
        this.requesting.startHour
      ).toLocaleTimeString("pt-BR");
      this.requesting.endHour = new Date(
        this.requesting.endHour
      ).toLocaleTimeString("pt-BR");
      this.requesting.dateSelect = new Date(
        this.requesting.dateSelect
      ).toLocaleDateString("pt-BR");
      this.requesting.message =
        "<p><b>Protocolo:</b> " +
        this.requesting.protocol +
        "</p>" +
        "<p><b>Solicitante:</b> " +
        this.requesting.requester +
        "</p>" +
        "<p><b>E-mail:</b> " +
        this.requesting.requesterMail +
        "</p>" +
        "<p><b>Empresa:</b> " +
        this.requesting.company +
        "</p>" +
        "<p><b>Câmera:</b> " +
        this.requesting.localeCam +
        "</p>" +
        "<p><b>Data:</b> " +
        this.requesting.dateSelect +
        "</p>" +
        "<p><b>Horário inicial:</b> " +
        this.requesting.startHour +
        "</p>" +
        "<p><b>Horário final:</b> " +
        this.requesting.endHour +
        "</p>" +
        "<p><b>Observações:</b> " +
        this.requesting.details +
        "</p>";

      this.requesting.created = Date.now();
      const firebaseWay =
        "mordor-request-recording/" +
        this.GetCurrentUser.activeFlag.toLowerCase() +
        "/" +
        new Date(this.requesting.protocol).getFullYear() +
        "/" +
        new Date(this.requesting.protocol)
          .toLocaleDateString("pt-br", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .split("/")[1] +
        "/" +
        this.requesting.protocol;
      const dateindex =
        "mordor-request-recording/" +
        this.GetCurrentUser.activeFlag.toLowerCase() +
        "/date-index/" +
        new Date(this.requesting.protocol)
          .toLocaleDateString("pt-BR")
          .replaceAll("/", "-");
      this.$store.dispatch("database/SetOnDatabase", {
        path: firebaseWay,
        object: this.requesting,
      });
      this.$store
        .dispatch("database/SetOnDatabase", {
          path: dateindex,
          object: true,
        })
        .then(() => {
          const sender = firebase.functions().httpsCallable("sendMail");
          sender(this.requesting).then(() => {
            this.LaunchToast(
              "A requisição " +
                this.requesting.protocol +
                " foi criado com êxito.",
              "is-success"
            );
            this.IsConfirmButtonLoading = false;
            this.resetData();
          });
        })
        .catch(() => {
          this.LaunchToast(
            "Ocorreu uma falha ao criar a requisição.",
            "is-danger"
          );
          this.IsConfirmButtonLoading = false;
        });
    },
    LaunchToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        type: type,
        position: "is-bottom",
        duration: 3000,
      });
    },
  },
  created() {},
};
</script>