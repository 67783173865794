<template>
  <div>
    <b-loading
      :is-full-page="isFullPage"
      v-model="isFirstLoading"
      :can-cancel="true"
    ></b-loading>
    <div v-if="!rondaState" class="columns">
      <div class="column is-6">
        <div v-if="IsStreaming">
          <BasePlayer :options="videoOptions" :videoSource="videoSource" />
        </div>
        <div v-else style="position: relative">
          <figure class="image is-16by9 mb-4" oncontextmenu="return false;">
            <img id="frame" class="image" :src="GetImageUrl" />
            <b-button
              @click="downloadImage"
              class="btn-high-left"
              icon-left="download"
              type="is-primary"
              rounded
              inverted
              outlined
              size="is-small"
            />
          </figure>
          <b-loading
            :is-full-page="isFullPage"
            v-model="isPresetManualLoading"
            :can-cancel="true"
          ></b-loading>
        </div>
      </div>
      <div
        class="column is-6 columns is-mobile is-gapless imageBack"
        :style="backgroundImage"
        style="position: relative"
      >
        <div class="column is-4 is-three-quarters-mobile">
          <b-button
            size="is-large"
            class="my-1 glassBtn"
            :class="{
              glassBtnActive: presets == presetsLoaded[this.presetSelected][1],
            }"
            @click="GetZoom(1)"
            expanded
            >1</b-button
          >
          <b-button
            size="is-large"
            class="my-1 glassBtn"
            :class="{
              glassBtnActive: presets == presetsLoaded[this.presetSelected][4],
            }"
            @click="GetZoom(4)"
            expanded
            >4</b-button
          >
          <b-button
            size="is-large"
            class="my-1 glassBtn"
            :class="{
              glassBtnActive: presets == presetsLoaded[this.presetSelected][7],
            }"
            @click="GetZoom(7)"
            expanded
            >7</b-button
          >
        </div>
        <div class="column is-4 is-three-quarters-mobile">
          <b-button
            size="is-large"
            class="my-1 glassBtn"
            :class="{
              glassBtnActive: presets == presetsLoaded[this.presetSelected][2],
            }"
            @click="GetZoom(2)"
            expanded
            >2</b-button
          >
          <b-button
            size="is-large"
            class="my-1 glassBtn"
            :class="{
              glassBtnActive: presets == presetsLoaded[this.presetSelected][5],
            }"
            @click="GetZoom(5)"
            expanded
            >5</b-button
          >
          <b-button
            size="is-large"
            class="my-1 glassBtn"
            :class="{
              glassBtnActive: presets == presetsLoaded[this.presetSelected][8],
            }"
            @click="GetZoom(8)"
            expanded
            >8</b-button
          >
        </div>
        <div class="column is-4 is-three-quarters-mobile">
          <b-button
            size="is-large"
            class="my-1 glassBtn"
            :class="{
              glassBtnActive: presets == presetsLoaded[this.presetSelected][3],
            }"
            @click="GetZoom(3)"
            expanded
            >3</b-button
          >
          <b-button
            size="is-large"
            class="my-1 glassBtn"
            :class="{
              glassBtnActive: presets == presetsLoaded[this.presetSelected][6],
            }"
            @click="GetZoom(6)"
            expanded
            >6</b-button
          >
          <b-button
            size="is-large"
            class="my-1 glassBtn"
            :class="{
              glassBtnActive: presets == presetsLoaded[this.presetSelected][9],
            }"
            @click="GetZoom(9)"
            expanded
            >9</b-button
          >
        </div>
        <b-loading
          :is-full-page="isFullPage"
          v-model="isPresetSelectedLoading"
          :can-cancel="true"
        ></b-loading>
      </div>
    </div>
    <div v-else class="columns">
      <div v-if="IsStreaming" class="column is-12">
        <div class="rondaStreaming">
          <BasePlayer :options="videoOptions" :videoSource="videoSource" />
        </div>
      </div>
    </div>

    <div v-if="streamState == 'transmitindo'">
      <MordorStreamWarning />
    </div>
    <div :class="{ ronda: !IsStreaming }" v-if="rondaState">
      <MordorRondaWarning :patrolService="patrolService" />
      <div v-if="this.presetLat && this.presetLong" class="column">
        <section class="hero">
          <div>
            <b-message type="is-info" size="is-small">
              Preset:
              <span class="has-text-black has-text-weight-bold">{{
                this.lastPreset
              }}</span
              >, Latitude:
              <span class="has-text-black has-text-weight-bold">{{
                this.presetLat
              }}</span
              >, Longitude:
              <span class="has-text-black has-text-weight-bold">{{
                this.presetLong
              }}</span>
            </b-message>
          </div>
        </section>
      </div>
      <div v-else class="column">
        <section class="hero">
          <div>
            <b-message type="is-warning" size="is-small">
              Preset:
              <span class="has-text-black has-text-weight-bold">{{
                this.lastPreset
              }}</span
              >, Sem latidude e longitude cadastradas
            </b-message>
          </div>
        </section>
      </div>
      <div class="column">
        <section
          class="hero"
          v-if="this.LastPatrolStartStopUser && this.LastPatrolStartStopTime"
        >
          <div>
            <b-message type="is-danger" size="is-small">
              Ronda ativada por:
              <span class="has-text-black has-text-weight-bold">{{
                this.LastPatrolStartStopUser
              }}</span>
              <span
                v-if="this.LastPatrolStartStopUserCompany"
                class="has-text-black has-text-weight-bold"
              >
                ({{ this.LastPatrolStartStopUserCompany }})</span
              >
              em
              <span class="has-text-black has-text-weight-bold">{{
                this.LastPatrolStartStopTime
              }}</span>
            </b-message>
          </div>
        </section>
        <section class="hero" v-else>
          <div>
            <b-message type="is-danger" size="is-small">
              Sem informações de ronda para esta camera
            </b-message>
          </div>
        </section>
      </div>
    </div>

    <div class="columns is-mobile btnsControl mt-4">
      <b-field
        grouped
        position="is-centered"
        class="column is-half is-offset-one-quarter mb-4"
      >
        <b-button
          class="mx-1 mt-3"
          @click="RequestStreaming"
          type="is-primary"
          :loading="IsStreamingButtonLoading"
          v-if="!IsStreaming"
        >
          <b-icon icon="play" class="mr-2" /> Iniciar Transmissão</b-button
        >
        <b-button
          class="mx-1 mt-3"
          @click="CloseStreaming"
          type="is-danger"
          v-else
          ><b-icon icon="stop" class="mr-2" /> Fechar Transmissão</b-button
        >

        <b-button
          class="mx-1 mt-3"
          @click="StartStopPatrol()"
          v-if="rondaState == 0"
          type="is-success"
        >
          <b-icon icon="eye" class="mr-2" />Iniciar Ronda</b-button
        >
        <b-button
          class="mx-1 mt-3"
          @click="StartStopPatrol()"
          v-else
          type="is-danger"
          ><b-icon icon="eye-slash" class="mr-2" /> Parar Ronda</b-button
        >
        <div class="column is-2">
          <b-field
            label="Preset"
            label-position="on-border"
            v-if="rondaState == 0"
          >
            <b-select v-model="presetSelected" expanded>
              <option
                v-for="i in Object.keys(presetsLoaded)"
                :key="i"
                :value="i"
              >
                {{ i }}
              </option>
            </b-select>
            <div
              v-if="this.presetLat && this.presetLong"
              class="is-small message is-info mx-1 ml-4"
            >
              <div class="message-body lat-long-message">
                Preset:
                <span class="has-text-black has-text-weight-bold">{{
                  this.presets
                }}</span
                >, Latitude:
                <span class="has-text-black has-text-weight-bold">{{
                  this.presetLat
                }}</span
                >, Longitude:
                <span class="has-text-black has-text-weight-bold">{{
                  this.presetLong
                }}</span>
              </div>
            </div>
            <div v-else class="is-small message is-warning mx-1 ml-4">
              <div class="message-body lat-long-message">
                Preset:
                <span class="has-text-black has-text-weight-bold">{{
                  this.presets
                }}</span
                >, Sem latidude e longitude cadastradas
              </div>
            </div>
          </b-field>
        </div>
      </b-field>
    </div>
    <div class="column mt-2" v-if="!rondaState">
      <section
        class="hero"
        v-if="this.LastPatrolStartStopUser && this.LastPatrolStartStopTime"
      >
        <div>
          <b-message type="is-danger" size="is-small">
            Ronda desativada por:
            <span class="has-text-black has-text-weight-bold">{{
              this.LastPatrolStartStopUser
            }}</span>
            <span
              v-if="this.LastPatrolStartStopUserCompany"
              class="has-text-black has-text-weight-bold"
            >
              ({{ this.LastPatrolStartStopUserCompany }})</span
            >
            em
            <span class="has-text-black has-text-weight-bold">{{
              this.LastPatrolStartStopTime
            }}</span>
          </b-message>
        </div>
      </section>
      <section class="hero" v-else>
        <div>
          <b-message type="is-danger" size="is-small">
            Sem informações de ronda para esta camera
          </b-message>
        </div>
      </section>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.glassBtn {
  margin-left: -2%;
  width: 97%;
  height: 31.2%;
  color: rgba(248, 244, 244, 0.331);
  font-size: 2rem;
  background: rgba(14, 0, 0, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.1px);
  -webkit-backdrop-filter: blur(2.1px);
  border: 1px solid rgb(0, 0, 0);
}
.glassBtnActive {
  background: rgba(14, 0, 0, 0.651);
  border: 1px solid red;
}
.imageBack {
  margin-top: 0.8rem;
  background-size: 97.5%;
  background-repeat: no-repeat;
}
.btn-high-left {
  position: absolute;
  bottom: 0;
  right: 0;
}
.btn {
  border-radius: 6px;
  font-weight: bolder;
  font-size: 1.8vh;
}
.has-vertically-aligned-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.has-side-margins {
  margin-left: 3.25rem;
}
.btnsControl {
  margin-top: -2%;
  margin-bottom: -3%;
}
.ronda {
  height: 57vh;
}
.rondaStreaming {
  width: 50%;
  margin: auto;
}
.lat-long-message {
  font-size: 0.8em;
}
</style>
<script>
import firebase from "../../../firebase.config.js";
import BasePlayer from "@/components/home/BasePlayer.vue";
import MordorRondaWarning from "@/components/mordor_camControl/MordorRondaWarning.vue";
import MordorStreamWarning from "@/components/mordor_camControl/MordorStreamWarning.vue";
export default {
  name: "MordorStream",
  data() {
    return {
      isFirstLoading: true,
      isListening: false,
      isFrameLoading: true,
      isPresetManualLoading: true,
      isPresetSelectedLoading: true,
      IsStreamingButtonLoading: false,
      isFullPage: false,
      ImageUrl: "",
      IsStreaming: false,
      LastImageTimestamp: 0,
      videoOptions: {
        autoplay: true,
        controls: true,
        preload: true,
        inactivityTimeout: 0,
        liveui: true,
        fluid: true,
        sources: null,
      },
      videoSource: "",
      streamingData: {
        email: "",
        user: "",
        startTime: "",
        endTime: "",
        totalTime: "",
      },

      tryCounter: 1,
      usersCompany: ["transpetro"],
      stateStream: true,
      irMode: false,

      rondaState: false,
      streamState: "",
      presets: "",
      presetSelected: "",
      presetsLoaded: "",
      img: "",
      backgroundImg: "",
      presetLat: "",
      presetLong: "",
      datesArraySort: [],
      LastPatrolStopDate: "",
      LastPatrolStartStopUser: "",
      LastPatrolStartStopUserCompany: "",
      LastPatrolStartStopTime: "",
      lastPreset: "",
      patrolService: "",
    };
  },

  components: {
    BasePlayer,
    MordorRondaWarning,
    MordorStreamWarning,
  },
  computed: {
    GetImageUrl() {
      // return this.ImageUrl
      return this.img;
    },

    GetCurrentUser() {
      return this.$store.getters["user/GetCurrentUser"];
    },
    GetIr() {
      return this.irMode;
    },
    GetSelectedCam() {
      return this.$store.getters["database/GetCurrentCamera"];
    },
    backgroundImage() {
      return {
        "background-image": "url('" + this.backgroundImg + "')",
        // ('https://firebasestorage.googleapis.com/v0/b/altave-harpia.appspot.com/o/sauronimages3%2Ftranspetro%2Fcam1%2FimagemTesteCelso.jpg?alt=media&token=fee2b26d-e2ce-417a-8769-48ac5f5e1e3b')"
      };
    },
    GetCurrentCamera() {
      return this.$store.getters["database/GetCurrentCamera"];
    },
    GetCameras() {
      return this.$store.getters["database/GetCameras"];
    },
  },
  watch: {
    presets: {
      handler(newPreset) {
        if (!this.rondaState && !this.isFirstLoading) {
          this.SetPresetManual(newPreset, this.GetSelectedCam);
          this.GetImagePresetManual(newPreset);
        }
      },
    },
    presetSelected: {
      handler(newPreset) {
        if (this.rondaState == 0 && !this.isFirstLoading) {
          this.SetPresetSelected(newPreset);
          this.GetImagePresetSelected(newPreset);
          this.SetPresetManual(newPreset, this.GetSelectedCam);
          this.GetImagePresetManual(newPreset);
        }
      },
    },
    GetSelectedCam: {
      handler() {
        this.isFirstLoading = true;
        if (this.IsStreaming) {
          this.CloseStreaming();
        }
        this.CheckStatus(true);
      },
    },
  },
  methods: {
    setRefToOff() {
      const numberOfCameras = this.GetCameras.length;
      for (let i = 1; i <= numberOfCameras; i++) {
        firebase
          .database()
          .ref(
            `sauron/${this.GetCurrentUser.activeFlag.toLowerCase()}/camcontrol/cam${i}`
          )
          .off();
      }
    },
    async CheckStatus(status) {
      await this.setRefToOff();
      let ref = firebase
        .database()
        .ref(
          "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/" +
            this.GetSelectedCam
        );
      if (status) {
        ref.on("value", (snapshot) => {
          this.streamState = snapshot.val().status.stream.toLowerCase();
          this.rondaState = snapshot.val().patrol;
          this.presets = snapshot.val().presetManual;
          this.presetsLoaded = Object.fromEntries(
            Object.entries(snapshot.val().presets).filter(
              (v) => v != null && v !== ""
            )
          );
          // this.presetsLoaded=snapshot.val().presets.filter(n => n)
          this.presetSelected = snapshot.val().presetSelected;
          this.lastPreset = snapshot.child("status/lastPreset").val();
          this.patrolService = snapshot.child("status/patrolService").val();
          this.GetLastUserStopPatrol();
          if (this.presets != 0) {
            this.GetPresetCoordinates(this.presets);
          } else {
            this.GetPresetCoordinates(this.lastPreset);
          }
        });
      } else {
        //importante finalizar o listener, pois senão, toda vez que o user acessar a aba de streaming, ele incrementa a função.
        ref.off();
      }

      setTimeout(() => this.GetImagePresetSelected(this.presetSelected), 1000);
      setTimeout(() => this.GetImagePresetManual(this.presets), 1000);
      setTimeout(() => (this.isFirstLoading = false), 1000);
    },
    saveDataReportPatrol(status) {
      let timestamp = Date.now();
      let date = new Date(timestamp)
        .toLocaleDateString("pt-BR")
        .split("/")
        .join("-");
      this.$store
        .dispatch("database/SetOnDatabase", {
          path:
            "mordor-history/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/ronda/" +
            this.GetSelectedCam +
            "/" +
            date +
            "/" +
            timestamp,
          object: {
            login: this.GetCurrentUser.email,
            name: this.GetCurrentUser.name,
            company: this.GetCurrentUser.company,
            status: status,
            date: timestamp,
          },
        })
        .then(() => {
          this.$store.dispatch("database/SetOnDatabase", {
            path:
              "mordor-history/" +
              this.GetCurrentUser.activeFlag.toLowerCase() +
              "/ronda/" +
              this.GetSelectedCam +
              "/" +
              "/date-index/" +
              date,
            object: true,
          });
        });
    },
    //Função para ativar/desativar a ronda.
    StartStopPatrol() {
      if (this.rondaState) {
        this.$store
          .dispatch("database/SetOnDatabase", {
            path:
              "sauron/" +
              this.GetCurrentUser.activeFlag.toLowerCase() +
              "/camcontrol/" +
              this.GetSelectedCam +
              "/patrol",
            object: 0,
          })
          .then(() => {
            this.saveDataReportPatrol(this.rondaState);
          })
          .then(() => {
            this.$store.dispatch("database/SetOnDatabase", {
              path:
                "sauron/" +
                this.GetCurrentUser.activeFlag.toLowerCase() +
                "/config/state/ronda/" +
                this.GetSelectedCam,

              object: Date.now(),
            });
            this.SetPresetManual(this.presetSelected, this.GetSelectedCam);

            this.LaunchToast("Ronda desativada.", "is-success");
            this.rondaState = false;
          })
          .catch((error) => {
            this.LaunchToast("Falha ao desativar ronda.", "is-danger");
            console.log(error);
          });
      } else {
        this.$store
          .dispatch("database/SetOnDatabase", {
            path:
              "sauron/" +
              this.GetCurrentUser.activeFlag.toLowerCase() +
              "/camcontrol/" +
              this.GetSelectedCam +
              "/patrol",
            object: 1,
          })
          .then(() => {
            this.$store.dispatch("database/SetOnDatabase", {
              path:
                "sauron/" +
                this.GetCurrentUser.activeFlag.toLowerCase() +
                "/config/state/ronda/" +
                this.GetSelectedCam,
              object: 0,
            });
            this.SetPresetManual(0, this.GetSelectedCam);
            this.saveDataReportPatrol(this.rondaState);
            this.LaunchToast("Ronda ativada.", "is-success");
            this.rondaState = true;
          })
          .catch((error) => {
            this.LaunchToast("Falha ao ativar ronda.", "is-danger");
            console.log(error);
          });
      }
    },
    HandlerToastWhenSetPresets(newPreset) {
      if (newPreset != 0) {
        this.LaunchToast(
          `Câmera setada para o ponto ${newPreset}`,
          "is-success"
        );
      }
    },
    async SetPresetManual(newPreset, cam) {
      const newPresetInt = parseInt(newPreset);
      try {
        await this.$store.dispatch("database/SetOnDatabase", {
          path: `sauron/${this.GetCurrentUser.activeFlag.toLowerCase()}/camcontrol/${cam}/presetManual`,
          object: newPresetInt,
        });
        this.HandlerToastWhenSetPresets(newPresetInt);
      } catch (error) {
        this.LaunchToast("Falha ao setar câmera.", "is-danger");
      }
    },
    async SetPresetSelected(newPreset) {
      const newPresetInt = parseInt(newPreset);
      try {
        await this.$store.dispatch("database/SetOnDatabase", {
          path: `sauron/${this.GetCurrentUser.activeFlag.toLowerCase()}/camcontrol/${
            this.GetSelectedCam
          }/presetSelected`,
          object: newPresetInt,
        });
        this.HandlerToastWhenSetPresets(newPresetInt);
      } catch (error) {
        this.LaunchToast("Falha ao setar câmera.", "is-danger");
      }
    },
    //Função para setar o preset zoom da camera.
    GetZoom(zoom) {
      let newPreset = this.presetsLoaded[this.presetSelected][zoom];
      if (newPreset == this.presets) {
        this.SetPresetManual(this.presetSelected, this.GetSelectedCam);
      } else if (newPreset != 0) {
        this.SetPresetManual(newPreset, this.GetSelectedCam);
      } else {
        this.LaunchToast("Local Indisponivel.", "is-danger");
      }
    },
    //Função para o inicio do streaming.
    RequestStreaming() {
      this.ToggleLoadings(true);
      if (this.IsStreaming) {
        this.CloseStreaming();
      } else {
        this.OpenStreaming();
      }
    },
    // Função que envia a solicitação do streaming para o kinesis, aguarda a resposta e envia a url para o player.
    OpenStreaming() {
      this.$store
        .dispatch("database/SetOnDatabase", {
          path:
            "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/" +
            this.GetSelectedCam +
            "/streaming",
          object: firebase.database.ServerValue.increment(1),
        })
        .then(() => {
          this.$store
            .dispatch("database/SetOnDatabase", {
              path:
                "sauron/" +
                this.GetCurrentUser.activeFlag.toLowerCase() +
                "/camcontrol/" +
                this.GetSelectedCam +
                "/watching/" +
                this.GetCurrentUser.uid,
              object: this.GetCurrentUser.email,
            })
            .then(() => {
              this.SetStreamingOnDisconnectHandler();
              this.$store.dispatch("database/SetOnDatabase", {
                path:
                  "sauron/" +
                  this.GetCurrentUser.activeFlag.toLowerCase() +
                  "/config/state/streaming/" +
                  this.GetSelectedCam,
                object: Date.now(),
              });
            });
          const ref = firebase
            .database()
            .ref(
              "sauron/" +
                this.GetCurrentUser.activeFlag.toLowerCase() +
                "/camcontrol/" +
                this.GetSelectedCam +
                "/status/kinesisVideoService"
            );
          const listeningTimeout = setTimeout(() => {
            ref.off();
            this.LaunchToast("Timeout ao tentar abrir streaming.", "is-danger");
            this.CloseStreaming();
          }, 60000);
          ref.on("value", (snapshot) => {
            if (snapshot.val()) {
              clearTimeout(listeningTimeout);
              ref.off();
              this.GetHLSStreaming();
              // this.$store.dispatch('database/SetOnDatabase', {
              //     path: 'sauron/' + this.GetCurrentUser.activeFlag.toLowerCase() + '/watching/' + this.GetCurrentUser.uid,
              //     object: this.GetCurrentUser.email,
              // }).then(() => { this.SetStreamingOnDisconnectHandler()})
            }
          });
        });
    },
    CloseStreaming() {
      this.GetImagePresetManual(this.presetSelected);
      this.$store.dispatch("database/SetOnDatabase", {
        path:
          "sauron/" +
          this.GetCurrentUser.activeFlag.toLowerCase() +
          "/config/state/streaming/" +
          this.GetSelectedCam,
        object: 0,
      });

      this.ToggleLoadings(false);
      if (
        this.GetCurrentUser.company.toLowerCase() !== "altave" &&
        this.IsStreaming
      ) {
        this.TimeCounterStreaming();
      }
      this.IsStreaming = false;
      this.$store.dispatch("database/RemoveFromDatabase", {
        path:
          "sauron/" +
          this.GetCurrentUser.activeFlag.toLowerCase() +
          "/camcontrol/" +
          this.GetSelectedCam +
          "/watching/" +
          this.GetCurrentUser.uid,
      });
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/" +
            this.GetSelectedCam +
            "/streaming",
          returnSnapshotVal: false,
        })
        .then((snapshot) => {
          if (snapshot.val() > 0) {
            return this.$store
              .dispatch("database/SetOnDatabase", {
                path:
                  "sauron/" +
                  this.GetCurrentUser.activeFlag.toLowerCase() +
                  "/camcontrol/" +
                  this.GetSelectedCam +
                  "/streaming",
                object: firebase.database.ServerValue.increment(-1),
              })
              .then(() => {
                return setTimeout(() => {
                  this.$store.dispatch(
                    "database/RemoveFromDatabase",
                    {
                      path:
                        "sauron/" +
                        this.GetCurrentUser.activeFlag.toLowerCase() +
                        "/camcontrol/" +
                        this.GetSelectedCam +
                        "/watching/" +
                        this.GetCurrentUser.uid,
                    },
                    5000
                  );
                });
              })
              .then(() => {
                this.CancelStreamingOnDisconnectHandler();
              });
          }
        });
    },
    GetStreamName() {
      const cameraNumber = this.GetSelectedCam.match(/\d+/g).join("");
      const activeFlag = this.GetCurrentUser.activeFlag.toLowerCase();
      return `mordor-${activeFlag}-${cameraNumber}`;
    },
    GetHLSStreaming() {
      const getKinesisVideoUrl = firebase
        .functions()
        .httpsCallable("getKinesisVideoUrl");
      getKinesisVideoUrl({ streamName: this.GetStreamName() })
        .then((data) => {
          this.videoSource = data.data.HLSStreamingSessionURL;
          if (this.videoSource == null && this.stateStream) {
            setTimeout(() => {
              this.tryCounter++;
              this.GetHLSStreaming();
            }, 1000);
          } else {
            this.streamingData.startTime = Date.now();
            this.tryCounter = 1;
            this.ToggleLoadings(false);
            this.IsStreaming = true;
          }
        })
        .catch(() => {
          this.LaunchToast(
            "Não há streamings pra essa operação.",
            "is-warning"
          );
        });
    },
    downloadImage() {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function () {
        const blob = xhr.response;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = new Date().getTime() + ".jpg";
        link.click();
        URL.revokeObjectURL(link.href);
      };
      xhr.open("GET", this.GetImageUrl);
      xhr.send();
    },
    SetStreaming() {
      this.stateStream = !this.stateStream;
      this.CloseStreaming();
    },
    SetStreamingOnDisconnectHandler() {
      this.$store
        .dispatch("database/SetOnDisconnectHandler", {
          method: "remove",
          path:
            "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/" +
            this.GetSelectedCam +
            "/watching/" +
            this.GetCurrentUser.uid,
        })
        .then(() => {

          this.$store
            .dispatch("database/SetOnDisconnectHandler", {
              method: "set",
              path:
                "sauron/" +
                this.GetCurrentUser.activeFlag.toLowerCase() +
                "/camcontrol/" +
                this.GetSelectedCam +
                "/streaming",
              object: firebase.database.ServerValue.increment(-1),
            })
            .then(() => {
              if (this.GetCurrentUser.company.toLowerCase() != "altave") {

                const data = new Date(this.streamingData.startTime)
                  .toLocaleDateString("pt-br", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                  .split("/");
                this.streamingData.endTime =
                  firebase.database.ServerValue.TIMESTAMP;
                this.streamingData.totalTime = "";
                this.$store.dispatch("database/SetOnDisconnectHandler", {
                  method: "set",
                  path:
                    "mordor-history/" +
                    this.GetCurrentUser.activeFlag.toLowerCase() +
                    "/streamingUsed/" +
                    this.GetSelectedCam +
                    "/" +
                    data[2] +
                    "/" +
                    data[1] +
                    "/" +
                    this.streamingData.startTime,
                  object: this.streamingData,
                });
              }
            });
        });
    },
    CancelStreamingOnDisconnectHandler() {
      this.$store
        .dispatch("database/SetOnDisconnectHandler", {
          method: "cancel",
          path:
            "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/" +
            this.GetSelectedCam +
            "/watching/" +
            this.GetCurrentUser.uid,
        })
        .then(() => {
          this.$store
            .dispatch("database/SetOnDisconnectHandler", {
              method: "cancel",
              path:
                "sauron/" +
                this.GetCurrentUser.activeFlag.toLowerCase() +
                "/camcontrol/" +
                this.GetSelectedCam +
                "/streaming",
            })
            .then(() => {
              const data = new Date(this.streamingData.startTime)
                .toLocaleDateString("pt-br", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
                .split("/");
              this.$store.dispatch("database/SetOnDisconnectHandler", {
                path:
                  "sauron/" +
                  this.GetCurrentUser.activeFlag.toLowerCase() +
                  "/camcontrol/" +
                  this.GetSelectedCam +
                  "/streamingUsed/" +
                  data[2] +
                  "/" +
                  data[1] +
                  "/" +
                  this.streamingData.startTime,
                method: "cancel",
              });
            });
        });
    },
    TimeCounterStreaming() {
      this.streamingData.endTime = Date.now();
      this.streamingData.email = this.GetCurrentUser.email;
      this.streamingData.user = this.GetCurrentUser.name;
      this.streamingData.totalTime =
        this.streamingData.endTime - this.streamingData.startTime;
      const data = new Date(this.streamingData.startTime)
        .toLocaleDateString("pt-br", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/");
      this.$store
        .dispatch("database/SetOnDatabase", {
          // path: 'sauron/' + this.GetCurrentUser.activeFlag.toLowerCase()+  '/camcontrol/'+this.GetSelectedCam  + '/streamingUsed/'+data[2]+'/'+data[1]+'/'+this.streamingData.startTime,
          path:
            "mordor-history/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/streamingUsed/" +
            this.GetSelectedCam +
            "/" +
            data[2] +
            "/" +
            data[1] +
            "/" +
            this.streamingData.startTime,
          object: this.streamingData,
        })
        .then(() => {
          this.CancelStreamingOnDisconnectHandler();
        });
    },
    ToggleLoadings(toggle) {
      this.IsStreamingButtonLoading = toggle;
    },
    GetImagePresetManual(preset) {
      if (preset) {
        this.isPresetManualLoading = true;
        this.$store
          .dispatch(
            "storage/GetMordorImageDownloadLink",
            this.GetCurrentUser.activeFlag.toLowerCase() +
              "/" +
              this.GetSelectedCam +
              "/presets/" +
              preset
          )
          .then((snp) => {
            if (typeof snp == "string") {
              this.img = snp;
              this.isPresetManualLoading = false;
            } else {
              this.LaunchToast(snp, "is-danger");
              this.isPresetManualLoading = false;
            }
          });
      }
      // Essas 3 linhas são para fechar o listen das cameras selecionadas anteriormente. Deve ser refatorado futuramente.
      //================================================================//
      firebase
        .database()
        .ref(
          "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/cam1/status/refreshPreset"
        )
        .off();
      firebase
        .database()
        .ref(
          "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/cam2/status/refreshPreset"
        )
        .off();
      firebase
        .database()
        .ref(
          "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/cam3/status/refreshPreset"
        )
        .off();
      firebase
        .database()
        .ref(
          "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/cam4/status/refreshPreset"
        )
        .off();
      //================================================================//

      let ref = firebase
        .database()
        .ref(
          "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/" +
            this.GetSelectedCam +
            "/status/refreshPreset"
        );

      ref.on("value", () => {
        this.$store
          .dispatch(
            "storage/GetMordorImageDownloadLink",
            this.GetCurrentUser.activeFlag.toLowerCase() +
              "/" +
              this.GetSelectedCam +
              "/presets/" +
              preset
          )
          .then((snp) => {
            if (typeof snp == "string") {
              this.img = snp;
              this.GetImagePresetSelected(this.presetSelected);
            }
          });
      });
    },
    GetImagePresetSelected(preset) {
      if (preset) {
        this.isPresetSelectedLoading = true;
        this.$store
          .dispatch(
            "storage/GetMordorImageDownloadLink",
            this.GetCurrentUser.activeFlag.toLowerCase() +
              "/" +
              this.GetSelectedCam +
              "/presets/" +
              preset
          )
          .then((snp) => {
            if (typeof snp == "string") {
              this.backgroundImg = snp;
              this.isPresetSelectedLoading = false;
            } else {
              this.isPresetSelectedLoading = false;
              this.LaunchToast(snp, "is-danger");
            }
          });
      }
    },
    LaunchToast(message, type) {
      this.$buefy.toast.open({
        //Displays toast with given properties below.
        message: message, //Message to display.
        type: type, //Toast's color.
        position: "is-bottom", //Toast's position.
        duration: 3000, //Toast's duration.
      });
    },
    GetPresetCoordinates(preset) {
      let newPreset = this.rondaState ? preset : this.presetSelected;

      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/" +
            this.GetSelectedCam +
            "/presets/" +
            newPreset +
            "/coordenadas/" +
            preset,
        })
        .then((snp) => {
          if (snp.exists()) {
            this.presetLat = snp.val().lat;
            this.presetLong = snp.val().long;
          } else {
            this.presetLat = null;
            this.presetLong = null;
          }
        });
    },
    capitalizeFirstLetter(string) {
      let capitalized = new String();
      try {
        capitalized = string.charAt(0).toUpperCase() + string.slice(1);
      } catch {
        capitalized = "";
      }
      return capitalized;
    },
    GetLastUserStopPatrol() {
      this.GetLastPatrolStopDate().then(() => {
        this.$store
          .dispatch("database/GetLimitedFromDatabase", {
            path:
              "mordor-history/" +
              this.GetCurrentUser.activeFlag.toLowerCase() +
              "/ronda/" +
              this.GetSelectedCam +
              "/" +
              this.LastPatrolStopDate,
            returnSnapshotVal: true,
            limit: 1,
          })
          .then((snapshot) => {
            if (snapshot) {
              this.LastPatrolStartStopUserCompany = this.capitalizeFirstLetter(
                Object.values(snapshot)[0].company
              );
              this.LastPatrolStartStopUser = Object.values(snapshot)[0].name;
              this.LastPatrolStartStopTime = new Date(
                Object.values(snapshot)[0].date
              ).toLocaleString("pt-BR");
            } else {
              this.LastPatrolStartStopUser = "";
              this.LastPatrolStartStopTime = "";
            }
          });
      });
    },
    GetLastPatrolStopDate() {
      this.datesArraySort = [];
      return this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "mordor-history/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/ronda/" +
            this.GetSelectedCam +
            "/date-index",
        })
        .then((dates) => {
          let datesArray = this.GetDatesArray(dates);
          this.datesArraySort = this.GetSortDatesArray(datesArray);
          this.LastPatrolStopDate =
            this.datesArraySort[this.datesArraySort.length - 1];
        });
    },
    GetDatesArray(dates) {
      let newArr = [];
      dates.forEach((date) => {
        newArr.push(date.key);
      });
      return newArr;
    },
    GetSortDatesArray(arr) {
      let newArr = arr.sort(function (a, b) {
        let dateASplit = a.split("-");
        let dateBSplit = b.split("-");
        var dateA = new Date(dateASplit[2], dateASplit[1] - 1, dateASplit[0]),
          dateB = new Date(dateBSplit[2], dateBSplit[1] - 1, dateBSplit[0]);
        return dateA - dateB;
      });
      return newArr;
    },
  },
  created() {},
  beforeDestroy() {
    if (this.IsStreaming) {
      this.CloseStreaming();
    }
  },
};
</script>
