<template>
    <div class="is-100-percent-height">
        <l-map v-if="WarningLocation" class="map" :zoom="16" :options="{zoomControl: false}" :center="[WarningLocation.latitude, WarningLocation.longitude]" :maxZoom="17" :minZoom="5">
            <l-tile-layer v-for="TileProvider in MapTileProviders" :key="TileProvider.id" :attribution="TileProvider.attribution" :url="TileProvider.url" layer-type="base" />
            <l-marker :lat-lng="[WarningLocation.latitude, WarningLocation.longitude]"></l-marker>
            <div v-if="GetCurrentUser.activeFlag === 'mordor-01-trovao' || GetCurrentUser.activeFlag === 'mordor-02-trovao' || GetCurrentUser.activeFlag === 'mordor-03-trovao' || GetCurrentUser.activeFlag === 'mordor-04-trovao'">
                <l-marker v-if="MordorLocations" :icon="MordorTowerIcon" :lat-lng="MordorLocations.towers[GetMordorLocationsNames]">
                    <l-tooltip>Torre: {{ GetMordorLocationsNames }}</l-tooltip>
                </l-marker>
                <l-polyline v-if="MordorLocations" :lat-lngs="[MordorLocations.towers[GetMordorLocationsNames],[WarningLocation.latitude, WarningLocation.longitude]]" :color="'#8cFF00'" dashArray="2,5"></l-polyline>
                <l-polyline v-if="MordorLocations" :lat-lngs="MordorLocations.lines[GetMordorLocationsNames]" :color="'#00FFFB'"></l-polyline>
            </div>
        </l-map>
        <p class="container" v-else>Sem geolocalização</p>
    </div>
</template>
<style lang="scss" scoped>
.map {
    z-index: 0;
}

.is-100-percent-height {
    height: 100%;
}
</style>
<script>
import { LMap, LTileLayer, LMarker, LPolyline, LTooltip } from 'vue2-leaflet'
export default {
    name: 'CardWarningDetailsModal',
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPolyline,
        LTooltip
    },
    props: {
        WarningLocation: Object
    },
    data() {
        return {
            MordorLocations: null,
            MordorTowerIcon: window.L.ExtraMarkers.icon({
                icon: "fa-video",
                markerColor: 'green',
                prefix: 'fa'
            }),
            MapTileProviders: [{
                    id: 1,
                    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                },
                {
                    id: 2,
                    attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    url: 'https://stamen-tiles-{s}.a.ssl.fastly.net/toner-hybrid/{z}/{x}/{y}{r}.png',
                    ext: 'png'
                }
            ]
        }
    },
    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
        GetMordorLocationsNames() {
            let locationName = ''
            switch (this.GetCurrentUser.activeFlag) {
                case 'Altave':
                    locationName = 'Atibaia'
                    break
                case 'mordor-01-trovao':
                    locationName = 'Atibaia'
                    break
                case 'mordor-02-trovao':
                    locationName = 'Itatiba01'
                    break
                case 'mordor-03-trovao':
                    locationName = 'Suzano'
                    break
                case 'mordor-04-trovao':
                    locationName = 'Itatiba02'
                    break
            }
            return locationName
        }
    },
    created() {
        this.$store.dispatch('database/GetMordorLocationsFromDatabase')
            .then(snapshot => { this.MordorLocations = snapshot.val() })
    }
}
</script>