<template>
  <div class="column">
    <section class="hero">
      <div>
        <b-message
          v-if="patrolService"
          type="is-success"
          size="is-small"
          class="ronda"
        >
          Ronda ativa
        </b-message>
        <b-message
          v-else
          type="is-warning"
          size="is-small"
          class="ronda"
          style="position: relative"
        >
          <b-loading :is-full-page="false" v-model="patrolServiceControl">
          </b-loading>
          Ativando ronda
        </b-message>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "MordorRondaWarning",
  props: {
    patrolService: Boolean,
  },
  data() {
    return {
      patrolServiceControl: !this.patrolService,
    };
  },
};
</script>
