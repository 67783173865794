<template>
  <div>
    <div>
      <div class="columns my-1 mx-1">
        <div class="column card my-4 mx-2">
          <p class="title">Sistema</p>
          <p class="statusOn is-size-4" v-if="streamLastTime < 1">Disponível</p>
          <p class="statusOff is-size-4" v-else>Indisponivel</p>
          <p class="is-size-7">
            Última atualização
            {{ new Date(streamingOnDemand).toLocaleString("pt-br") }}
          </p>

          <p class="is-size-4 mt-3">
            <b>Ronda</b>
          </p>
          <p
            class="statusOn is-size-4"
            v-if="systemPatrol && streamLastTime < 1"
          >
            Ativo
          </p>
          <p class="statusOff is-size-4" v-else>Inativo</p>
        </div>
        <div
          v-if="GetCurrentUser.activeFlag.toLowerCase() === 'transpetro'"
          class="column card my-4 mx-2"
        >
          <p class="title">Streaming</p>

          <div>
            <p class="is-size-2 has-text-weight-bold">
              {{ MilisecondsConverter(streamTimeUsed) }}/{{
                streamTimeLimit
              }}min
            </p>

            <p class="is-size-3 has-text-weight-medium my-5">
              {{ streamTimeUsedPercent }}% utilizado
            </p>
          </div>
        </div>

        <div
          v-if="GetCurrentUser.activeFlag.toLowerCase() === 'transpetro'"
          class="column card my-4 mx-2"
        >
          <p class="title">Últimas Visualizações</p>
          <b-table :data="watchings" class="my-table">
            <b-table-column label="Solicitante" v-slot="props">
              {{ props.row[1].user }}
            </b-table-column>
            <b-table-column label="Data" v-slot="props">
              {{ new Date(props.row[1].startTime).toLocaleDateString("pt-BR") }}
              <br />
              ({{
                new Date(props.row[1].startTime).toLocaleTimeString("pt-BR")
              }}
              -
              {{ new Date(props.row[1].endTime).toLocaleTimeString("pt-BR") }})
            </b-table-column>
            <b-table-column label="Total" v-slot="props">
              {{
                MilisecondsConverter(
                  props.row[1].endTime - props.row[1].startTime
                )
              }}
            </b-table-column>
          </b-table>
        </div>
      </div>
      <div class="columns my-1 mx-1">
        <div class="column card my-4 mx-2">
          <p class="title">Requisições de Gravação</p>
          <b-table
            :data="requestsRecordings"
            class="my-table"
            v-if="requestsRecordings.length"
          >
            <b-table-column label="Solicitante" v-slot="props">
              {{ props.row[1].requester }}
            </b-table-column>
            <b-table-column
              label="Data da Solicitação"
              v-slot="props"
              field="created"
            >
              {{ new Date(props.row[1].created).toLocaleString() }}
            </b-table-column>
            <b-table-column
              label="Período Solicitado"
              v-slot="props"
              field="dateSelect"
            >
              {{ props.row[1].dateSelect }} <br />
              ({{ props.row[1].startHour.substring(0, 5) }} à
              {{ props.row[1].endHour.substring(0, 5) }})
            </b-table-column>
          </b-table>
          <div class="statusOff is-size-4" v-else>Sem dados.</div>
        </div>

        <!-- <div class="column card my-4 mx-2" v-if="GetCurrentUser.activeFlag.toLowerCase()!='mrd-03-vale'"> -->
        <div class="column card my-4 mx-2">
          <span class="title">Links</span>

          <ul class="separator">
            <li>
              <p class="is-size-4 has-text-weight-bold">Relatórios</p>
              <p class="mt-5 is-size-5">
                <a :href="links.report" target="_blank">
                  <b> Link do drive.</b>
                </a>
              </p>
            </li>

            <li>
              <p class="is-size-4 mt-4 has-text-weight-bold">Vídeos</p>
              <p class="mt-5 is-size-5">
                <a :href="links.videos" target="_blank">
                  <b>Link do drive.</b>
                </a>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scope>
.card {
  background-color: grayscale($color: #eceaea8a);
  height: 20rem;
}
.statusOn {
  color: #01d401;
  font-size: 300%;
  font-weight: 900;
}
.statusOff {
  color: #ca0000;
  font-size: 300%;
  font-weight: 900;
}
.my-table {
  height: 14rem;
  overflow-y: auto;
  text-align: left;
}

ul.separator li {
  padding: 0.5em 0;
  border-bottom: 1px solid #000;
  text-align: left;
}
</style>

<script>
import firebase from "../../../firebase.config";

export default {
  name: "MordorDashboard2",
  data() {
    return {
      requestsRecordings: {},
      statusSystem: "",
      lastVerificationSystem: "--:--",
      streamTimeLimit: 0,
      streamTimeUsed: 0,
      streamTimeUsedPercent: 0,
      streamLastTime: 0,
      streamData: 0,
      oldStreaming: 1,
      oldFrame: 1,
      oldFrameMordor: 1,
      today: new Date(Date.now())
        .toLocaleDateString("pt-br", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/"),
      watchings: [],
      site: "",
      availability: "0",
      links: {},
      systemPatrol: "",
      streamingOnDemand: null,
    };
  },
  computed: {
    GetCurrentUser() {
      return this.$store.getters["user/GetCurrentUser"];
    },
    GetSelectedCam() {
      return this.$store.getters["database/GetCurrentCamera"];
    },
  },
  methods: {
    FindRequests() {
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "mordor-request-recording/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/date-index",
        })
        .then((dates) => {
          if (dates) {
            Object.keys(dates.val()).forEach(() => {
              this.$store
                .dispatch("database/GetFromDatabase", {
                  path:
                    "mordor-request-recording/" +
                    this.GetCurrentUser.activeFlag.toLowerCase() +
                    "/" +
                    this.today[2] +
                    "/" +
                    this.today[1],
                  returnSnapshotVal: true,
                })
                .then((result) => {
                  result
                    ? (this.requestsRecordings =
                        Object.entries(result).reverse())
                    : ""; //Arranges descending and transforms into an array.
                });
            });
          }
        });
    },
    FindLastWatches() {
      if (this.GetCurrentUser.activeFlag.toLowerCase() != "transpetro") return;
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "mordor-history/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/streamingUsed/" +
            this.GetSelectedCam +
            "/" +
            this.today[2] +
            "/" +
            this.today[1],
          returnSnapshotVal: true,
        })
        .then((el) => {
          this.streamData = el;
          this.watchings = Object.entries(el).reverse(); //Arranges descending and transforms into an array.
        })
        .finally(() => {
          this.verifyStreamingQuote();
        });
    },
    MilisecondsConverter(miliseconds) {
      let time = "";
      if (miliseconds < 60000) {
        time = (miliseconds / 1000).toFixed(1) + "s";
      }
      if (miliseconds >= 60000) {
        time = (miliseconds / 60000).toFixed(0) + "min";
      }
      return time;
    },
    verifyStatus() {
      let ref = firebase
        .database()
        .ref(
          "sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/" +
            this.GetSelectedCam
        );

      ref.on("value", (snapshot) => {
        console.log("on");
        this.systemPatrol = snapshot.val().status.patrolService;
        this.links = snapshot.val().links;
        this.streamTimeLimit = snapshot
          .child("config")
          .child("streamTimeLimit")
          .val();
      });
    },
    verifyStreamingQuote() {
      this.streamTimeUsed = 0;
      if (this.streamData) {
        Object.keys(this.streamData).forEach((key) => {
          this.streamTimeUsed +=
            this.streamData[key].endTime - this.streamData[key].startTime;
        });
      }
      this.streamTimeUsedPercent =
        this.streamTimeUsed >= 60000
          ? (this.streamTimeUsed / 60000 / (this.streamTimeLimit / 100))
              .toFixed(2)
              .replace(".", ",")
          : 0;
      this.lastVerificationSystem = new Date(Date.now()).toLocaleTimeString(
        "pt-BR",
        { hour: "2-digit", minute: "2-digit" }
      );
    },
    verifyAvailability() {
      this.availability = "carregando...";
      // this.site = this.GetCurrentUser.activeFlag == 'mrd-02-vale'?this.GetSites[1]:this.GetSites[0]
      this.site =
        this.GetCurrentUser.activeFlag == "mrd-03-vale"
          ? this.GetSites[1]
          : this.GetSites[0];
      this.availability = this.site.availability.current.availability;
    },
    timer() {
      clearInterval(this.counter);
      this.counter = setInterval(this.GetStreamingOnDemand, 30000);
    },
    GetStreamName() {
      let streamName = "";
      switch (this.GetSelectedCam) {
        case "cam1":
          streamName = `mordor-${this.GetCurrentUser.activeFlag.toLowerCase()}-1`;
          break;
        case "cam2":
          streamName = `mordor-${this.GetCurrentUser.activeFlag.toLowerCase()}-2`;
          break;
        case "cam3":
          streamName = `mordor-${this.GetCurrentUser.activeFlag.toLowerCase()}-3`;
          break;
        default:
          streamName = `mordor-${this.GetCurrentUser.activeFlag.toLowerCase()}-1`;
      }
      return streamName;
    },
    GetStreamingOnDemand() {
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "mordor-sites/" +
            this.GetStreamName() +
            "/hms/status/streamingOnDemand",
          returnSnapshotVal: true,
        })
        .then((timestampStreamingOnDemand) => {
          this.streamingOnDemand = timestampStreamingOnDemand;
          let timestampNow = Date.now();
          this.streamLastTime = parseInt(
            (timestampNow - timestampStreamingOnDemand) / 60000
          );
          this.$emit("streamingOnDemandLastTime", this.streamLastTime);
        });
    },
  },
  mounted() {
    if (this.GetCurrentUser) {
      this.$store.dispatch(
        "database/bindSites",
        this.GetCurrentUser.activeFlag
      );
    }
  },
  created() {
    this.GetStreamingOnDemand();
    this.timer();
  },
  watch: {
    GetSelectedCam: {
      handler() {
        this.verifyStatus();
        this.FindLastWatches();
        this.FindRequests();
        this.GetStreamingOnDemand();
      },
      deep: true, //Watch for changes on the entire user object, not just its first level.
    },
    GetUserActiveFlag: {
      handler() {
        this.GetStreamingOnDemand();
        this.verifyStatus();
        this.FindLastWatches();
        this.FindRequests();
      },
      deep: true,
    },
  },
};
</script>
